import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { splitAt } from "../../../lib/utils";
import Typography from "../Typography";

import styles from "./styles";

const CollapsibleText = ({
  children,
  classes,
  className,
  color,
  legend,
  trimLength,
  variant,
  ...props
}) => {
  const [visible, toggleVisibility] = useState(false);
  const content = splitAt(children, trimLength);

  const renderCollapsible = () => (
    <Typography
      className={`${className} ${classes.root} ${classes[variant]} ${classes[color]}`}
      {...props}
    >
      <Typography
        component="strong"
        className={classes.heading}
        variant="body1"
      >
        {content[0]}
      </Typography>

      <Typography
        component="span"
        className={`${classes.content} ${visible && classes.visible}`}
        variant="body1"
      >
        {content[1]}
      </Typography>

      <Typography
        component="span"
        className={`${classes.ellipsis} ${
          (visible || !trimLength) && classes.visible
        }`}
      >
        {"..."}
      </Typography>

      {legend && (
        <Typography
          className={classes.legend}
          component="a"
          onClick={() => toggleVisibility(!visible)}
          variant="body2"
        >
          {visible ? "show less" : legend}
        </Typography>
      )}
    </Typography>
  );

  const renderDefault = () => (
    <Typography
      className={`${className} ${classes.root} ${classes[variant]} ${classes[color]}`}
      {...props}
    >
      <Typography component="strong" className={classes.heading}>
        {content}
      </Typography>
    </Typography>
  );

  return typeof content === "string" ? renderDefault() : renderCollapsible();
};

CollapsibleText.defaultProps = {
  classes: {},
  className: "",
  color: "primary",
  legend: "",
  trimLength: 60,
  variant: "default",
};

CollapsibleText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.string,
  legend: PropTypes.string,
  trimLength: PropTypes.number,
  variant: PropTypes.string,
};

export default withStyles(styles)(CollapsibleText);
