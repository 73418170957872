import { Popover as MuiPopover } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  paper: {
    background: theme.palette.grey[100],
  },
});

const Popover = (props) => <MuiPopover {...props} />;

export default withStyles(styles)(Popover);
