import MuiSource from "../Source";

import {
  getSrcSet,
  breakpoints,
  transformations as baseTransformations,
} from "./utils";

const Source = ({
  breakpoint,
  source = {},
  type = "image/jpeg",
  transformations: rootTransformations = {},
  ...rest
}) => {
  const sourceTransformations = source.transformations || {};
  const sourcePostTransformations = source.postTransformations || {};
  const fetchFormat =
    type === "image/jpeg" ? "jpg" : type.replace("image/", "");

  const transformations = {
    ...baseTransformations,
    ...rootTransformations,
    ...sourceTransformations,
    fetchFormat,
  };
  const postTransformations = {
    ...sourcePostTransformations,
    height: source.height || 0,
    width: source.width || 0,
    gravity: "auto:subject",
    crop: "fill",
  };

  if (!source?.cloudId && !source?.src) return null;

  return (
    <MuiSource
      type={type}
      media={breakpoints[breakpoint]?.media || breakpoints.default.media}
      srcSet={`
        ${getSrcSet({
          //   cloudId: source.cloudId,
          //   src: source.src,
          //   dpr: 3.0,
          //   transformations,
          //   postTransformations,
          // })} 3x,
          // ${getSrcSet({
          cloudId: source.cloudId,
          src: source.src,
          dpr: 2.0,
          transformations,
          postTransformations,
        })} 2x,
        ${getSrcSet({
          cloudId: source.cloudId,
          src: source.src,
          dpr: 1.0,
          transformations,
          postTransformations,
        })} 1x,
      `}
      {...rest}
    />
  );
};

export default Source;
