import { makeStyles } from "@mui/styles";

const avatarSizeMobile = 80;
const avatarSizeDesktop = 120;

const styles = (theme) => ({
  root: {
    overflow: "unset",
    marginTop: avatarSizeMobile / 2,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      marginTop: avatarSizeDesktop / 2,
    },
  },

  header: {},

  userWrapper: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },

  summary: {},

  userLink: {
    "&:hover": {
      "& $avatar img": {
        transform: "scale(1.2)",
      },

      "& $userName": {
        "& span": {
          color: theme.palette.common.highlight,
        },
      },
    },
  },

  userName: {},

  avatar: {
    width: avatarSizeMobile,
    height: avatarSizeMobile,
    marginTop: avatarSizeMobile * -0.5,
    marginBottom: theme.spacing(),

    [theme.breakpoints.up("md")]: {
      width: avatarSizeDesktop,
      height: avatarSizeDesktop,
      marginTop: avatarSizeDesktop * -0.5,
    },

    "& img": {
      transform: "scale(1)",
      transformOrigin: "50%",
      transition: theme.transitions.create("transform", {
        duration: 200,
        easing: theme.transitions.easing.easeIn,
      }),
    },
  },

  container: {
    padding: theme.spacing(2),
  },

  content: {
    color: theme.palette.common.black,
  },
});

export default makeStyles(styles);
