import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import { IconClose } from "../icons";
import Modal from "../Modal";
import Slider from "../Slider";
import Slide from "./Slide";

import styles from "./styles";

const Gallery = ({
  classes = {},
  images = [],
  sliderProps = {},
  selected = null,
  infinite = true,
  open = false,
  handleClose = () => {},
}) => {
  const [currSlide, setCurrSlide] = useState("");
  const [brokenImageIndexes, setBrokenImageIndex] = useState("");
  const safelyLoadedImages = images.filter(
    (image, i) => !brokenImageIndexes.includes(i),
  );

  const handleKeydown = (e) => {
    if (e.key === "Escape") {
      handleClose(e);
    }
  };

  const beforeSlideChange = (curr, next) => {
    setCurrSlide(next);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, false);

    return () => {
      document.removeEventListener("keydown", handleKeydown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      classes={{
        root: classes.modal,
        row: classes.modalRow,
        paper: classes.modalPaper,
        closeBtn: classes.modalCloseBtn,
        modalContainer: classes.modalContainer,
      }}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      showCloseBtn={false}
      open={open}
      fullScreen
    >
      <section className={classes.gallerySection}>
        <div className={classes.galleryRow}>
          <Slider
            classes={{
              root: classes.slider,
              arrow: classes.sliderArrows,
            }}
            beforeChange={beforeSlideChange}
            initialSlide={selected || 0}
            infinite={infinite}
            focusOnSelect
            {...sliderProps}
          >
            {safelyLoadedImages.map((imgProps, i, arr) => (
              <div className={classes.sliderContainer} key={i}>
                <Slide
                  index={i}
                  classes={classes}
                  heading={imgProps?.heading || `${i + 1} of ${arr.length}`}
                  setBrokenImageIndex={setBrokenImageIndex}
                  currSlide={currSlide}
                  imgProps={imgProps}
                />
              </div>
            ))}
          </Slider>
          <IconButton
            className={classes.modalCloseBtn}
            onClick={handleClose}
            size="large"
          >
            <IconClose size="md" viewBox="-6 -6 212 212" />
          </IconButton>
        </div>
      </section>
    </Modal>
  );
};

Gallery.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  infinite: PropTypes.bool,
  open: PropTypes.bool,
  images: PropTypes.array,
  selected: PropTypes.number,
};

export default withStyles(styles)(Gallery);
