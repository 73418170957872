// import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import { IconClose, IconUpload } from "./icons";
import Loading from "./Loading";

// TODO: install jss-extend and remove styles below
const styleLabelProps = {
  cursor: "pointer",
  height: "100%",
  // padding: "0 0.2em",
  position: "relative",
  transform: "none",
  width: "100%",
  maxWidth: "unset",
};

const styleLoadingProps = {
  backgroundColor: "rgba(232,232,232,0.7)",
  cursor: "progress",
  height: "100%",
  position: "absolute",
  width: "100%",
  zIndex: 10,
};

const styleInputProps = {
  bottom: 0,
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  visibility: "hidden",
  width: "100%",
};

const styleProgressBarProps = {
  bottom: 0,
  left: 0,
  margin: "auto",
  position: "absolute",
  right: 0,
  top: 0,
};

const styles = (theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
  },

  // variant={default}
  default: {},

  // variant={small}
  small: {
    height: "100%",
    // marginLeft: theme.gutter,
    position: "relative",
    width: "100%",

    "& $label": {
      ...styleLabelProps,
      backgroundColor: "rgba(240, 240, 236, 0.5)",
      border: `3px solid ${theme.palette.grey[250]}`,
      minHeight: 75,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      // "&:hover": {
      //   backgroundColor: "rgba(0, 0, 0, 0.05)",

      //   "& $icon circle": {
      //     fill: theme.palette.common.highlight
      //   }
      // }
    },

    "& $labelText, & $asterisk": {
      display: "none",
    },

    "& $loading": {
      ...styleLoadingProps,
    },

    "& $progressBar": {
      ...styleProgressBarProps,
    },

    "& .MuiInputBase-input": {
      ...styleInputProps,
    },

    "& $svgIcon": {
      "& > svg": {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "50%",
        color: theme.palette.common.white,
        display: "block",
        height: 44,
        overflow: "hidden",
        width: 44,
      },

      "& circle": {
        color: theme.palette.secondary.main,
      },
    },
  },

  // variant={large}
  large: {
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
    width: "100%",
    border: `1px dotted ${theme.palette.grey[400]}`,

    "& $label": {
      ...styleLabelProps,
    },

    "& $labelText": {
      visibility: "hidden",
      position: "absolute",
    },

    "& $asterisk": {
      display: "none",
    },

    "& $loading": {
      ...styleLoadingProps,
    },

    "& $progressBar": {
      ...styleProgressBarProps,
    },

    "& .MuiInputBase-input": {
      ...styleInputProps,
    },

    "& $svgIcon": {
      display: "block",
      width: "100%",

      "& > svg": {
        display: "block",
        height: 118,
        margin: "4em auto",
        width: 142,
      },
    },
  },

  icon: {
    "& $label": {
      ...styleLabelProps,
    },

    "& $labelText": {
      visibility: "hidden",
      position: "absolute",
    },

    "& .MuiInputBase-input": {
      visibility: "hidden",
    },
  },

  formControl: {},
  label: {},
  labelText: {},
  svgIcon: {},
  loading: {},
  progressBar: {},
  asterisk: {},
  input: {},
  errorMsg: {},
});

const Uploader = ({
  className,
  classes,
  error,
  icon,
  label,
  loading,
  multiple,
  name,
  required,
  value,
  variant,
  inputRef,
  onClick,
  ...rest
}) => {
  // const [isTouched, touchInput] = useState(false);
  const hasError = !!error; // && isTouched;

  return (
    <FormControl
      className={`${className} ${classes.root} ${classes[variant]}`}
      error={hasError}
      required={required}
      onClick={onClick}
    >
      {loading && (
        <div className={classes.loading}>
          <Loading
            className={classes.progressBar}
            color="secondary"
            size={50}
          />
        </div>
      )}
      {label && (
        <InputLabel
          classes={{
            root: classes.label,
            asterisk: classes.asterisk,
          }}
          htmlFor={`uploader-${name}`}
        >
          {variant === "large" && (
            <IconUpload className={classes.svgIcon} viewBox="0 0 142 118" />
          )}
          {variant === "small" && (
            <IconClose className={classes.svgIcon} variant="upRightCorner" />
          )}
          {variant === "icon" && icon}
          <span className={classes.labelText}>{label}</span>
        </InputLabel>
      )}
      <InputBase
        className={classes.input}
        inputProps={{
          multiple,
          id: `uploader-${name}`,
          name,
          required,
          value,
          inputRef,
          ...rest,
        }}
        placeholder="Upload an image"
        type="file"
      />
      {hasError && (
        <FormHelperText
          id={`field-error-${name}`}
          className={classes.errorMsg}
          component="span"
          error={hasError}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Uploader.defaultProps = {
  className: "",
  classes: {},
  error: "",
  icon: null,
  inputRef: null,
  label: "",
  loading: false,
  multiple: true,
  name: "",
  required: false,
  value: "",
  variant: "default",
};

Uploader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.string,
  icon: PropTypes.element,
  inputRef: PropTypes.any,
  label: PropTypes.any,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  variant: PropTypes.string,
};

export default withStyles(styles)(Uploader);
