import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},

  isActive: {},

  instantSearch: {
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      // margin: "0 1em",
      position: "relative",
    },
  },

  // SearchInput
  searchWrapper: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: 45,
    },
  },

  searchInput: {
    margin: 0,
    fontWeight: theme.typography.fontWeightBold,

    "& input": {
      fontSize: theme.atlasGuru.fontSizes.h6.xs,
    },

    [theme.breakpoints.up("md")]: {
      border: "solid transparent",
      borderWidth: "1px 1px 0",
    },

    "& fieldset": {
      border: 0,
    },
  },

  inputWrap: {
    paddingRight: theme.spacing(2),
  },

  input: {
    fontSize: theme.atlasGuru.fontSizes.caption.sm,
    color: theme.palette.grey[450],
    border: "none",
    height: `${theme.gutter * 1.8}px`,
    padding: "0 1em",
    textTransform: "capitalize",

    "&::placeholder": {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      opacity: 1,
      textAlign: "center",
    },
  },

  formControFooter: {
    display: "none",
  },

  icon: {
    alignItems: "center",
    bottom: 0,
    cursor: "pointer",
    display: "flex",
    margin: "auto",
    position: "absolute",
    right: theme.spacing(2),
    top: 0,

    "& > span": {
      display: "inline-flex",
    },

    "& svg": {
      color: `${theme.palette.grey[700]} !important`,
    },
  },

  // Results List
  results: {
    background: theme.palette.common.white,
    border: `solid ${theme.palette.primary.main}`,
    borderWidth: "0 1px 1px",
    color: theme.palette.common.black,
    maxHeight: "calc(85vh - 100px)",
    overflow: "auto",
    position: "absolute",
    top: "calc(100% + 19px)",
    width: "100%", // width: `calc(100% - ${theme.gutter * 2}px)`,
    left: 0,
    zIndex: 20,

    [theme.breakpoints.only("sm")]: {
      maxHeight: "calc(80vh - 200px)",
    },

    [theme.breakpoints.up("md")]: {
      top: "100%",
      width: `calc(100% + ${theme.spacing(20)})`,
      transform: `translateX(${theme.spacing(-10)})`,
      // maxWidth: 845,
      // width: "65vw",
    },
  },

  resultsWrapper: {
    // marginTop: theme.spacing(2),
  },

  resultsAside: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
    textTransform: "none",

    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      padding: `0 0 0 ${theme.spacing(2)}`,
    },
  },

  resultsAsideTitle: {
    ...theme.typography.subtitle1,
    margin: "0.2em 0 1em 0",
    lineHeight: "1em",

    "& span": {
      fontSize: "0.9em",
      letterSpacing: "0.8px",
    },
  },

  resultsAsideTopicsWrapper: {
    alignItems: "flex-start !important",
    flexFlow: "column",
    margin: "0 0 2em !important",
  },

  resultsAsideTopicsNav: {
    columnCount: 2,
    width: "80%",
  },

  resultsAsideTopicsLink: {
    display: "block",
    marginBottom: "0.8em",
    width: "calc(100% - 1em)",

    "&:hover $resultsAsideTopicsText": {
      color: theme.palette.common.highlight,
    },

    "&, & $resultsAsideTopicsText": {
      color: theme.palette.secondary.main,
      lineHeight: 1.1,
    },
  },

  resultsAsideTopicsText: {
    whiteSpace: "normal !important",
  },

  resultsListItem: {
    alignItems: "flex-start",
  },

  resultsItem: {
    border: "none !important",
    cursor: "pointer",
    display: "flex",
    flex: 1,
    flexFlow: "column",
    justifyContent: "center",
    minHeight: "4em",
    paddingRight: "1em !important",
    textTransform: "none",
  },

  resultsLink: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },

  resultsTitle: {
    ...theme.typography.h4,
  },

  resultsSubTitle: {
    ...theme.typography.h5,
    color: `${theme.palette.grey[150]} !important`,
    fontWeight: theme.typography.fontWeightBold,
  },

  resultImage: {
    display: "block",
    height: 56,
    overflow: "hidden",
    marginTop: "0.9rem",
    width: 100,

    "& img": {
      width: "100%",
    },
  },

  seeAllReports: {
    margin: `${theme.spacing(4)} ${theme.spacing(2)}`,
    maxWidth: "50%",
  },

  moreResults: {
    color: theme.palette.secondary.main,
    textTransform: "uppercase",
  },

  noResultsListItem: {
    background: "transparent !important",
  },

  noResultsItemText: {
    border: "none !important",
    color: theme.palette.primary.main,
    textTransform: "none",
    paddingBottom: "0 !important",
  },

  noResultsTitle: {
    ...theme.typography.h4,
    color: `${theme.palette.grey[600]} !important`,
    fontWeight: "normal !important",
    lineHeight: "1.6 !important",
    marginBottom: 0,
    paddingBottom: 0,
  },

  noResultsSubTitle: {
    ...theme.typography.h5,
    fontSize: "0.8em",
    textDecoration: "underline",
    textTransform: "none",
  },

  countriesListItem: {
    padding: 0,

    "& a": {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  regionsNav: {
    "& $regionsItemIcon": {
      marginRight: "0.5em",

      "&.icon > svg": {
        color: theme.palette.common.highlight,
        height: 50,
        width: 50,
      },
    },
  },

  regionsNavLink: {
    ...theme.typography.subtitle2,
    alignItems: "center",
    display: "flex",
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: "0.6em",
    textTransform: "none",
    lineHeight: 1,

    "& span": {
      color: theme.palette.secondary.main,
    },

    "&:hover span": {
      color: theme.palette.secondary.dark,
    },
  },

  regionsItemIcon: {
    marginRight: "0.5em",
  },
}));

export default useStyles;
