// TODO: remove this file and update files that use it

import { useCallback, useEffect, useMemo, useState } from "react";
import { removeEmpty } from "@/lib/utils";
import {
  fetchItinerary,
  fetchItineraryPreview,
  publishItinerary,
  removeLocation,
  removePlace,
  unpublishItinerary,
  upsertItinerary,
  upsertLocation,
  upsertLocationsBatch,
  upsertLocationsOrder,
  upsertPlace,
} from "./api";
import { initializeApollo } from "@/lib/apolloClient";

// need to be parse as Number
const numberInputs = ["yearTravelled", "numOfDays"];

const sanitizeData = (data) => {
  const newData = { ...data };

  for (const field in newData) {
    const value = newData[field];
    // the API doesn't allow "", if value is emtpy it should send null in order to set it as empty
    if (value === "") {
      newData[field] = null;
      continue;
    }

    if (numberInputs.includes(field)) {
      newData[field] = Number(value);
    }
  }
  return newData;
};

export const useItinerary = ({
  id,
  options = {},
  cached = true,
  preview = false, // working draft?
}) => {
  const [itinerary, setItinerary] = useState(undefined);
  const [error, setError] = useState(null);
  const client = initializeApollo();
  // loading states
  const [isFetching, setIsFetching] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  const fetch = useCallback(
    async (itineraryId, options = {}) => {
      try {
        setError(null);
        setIsFetching(true);

        options.variables = {
          id: itineraryId || itinerary.itineraryId,
        };

        // console.log("fetching itinerary");
        const response = preview
          ? await fetchItineraryPreview({
              client,
              options,
              cached,
            })
          : await fetchItinerary({ client, options, cached });
        setItinerary(response);
      } catch (error) {
        setError({
          type: "fetch",
          message: error,
        });
      } finally {
        setIsFetching(false);
      }
    },
    [cached, client, itinerary?.itineraryId, preview]
  );

  const save = useCallback(
    async (input = {}, options = {}) => {
      try {
        setError(null);
        setIsSaving(true);

        const itineraryId = itinerary?.itineraryId || id || null;
        const data = itineraryId ? { itineraryId, ...input } : input;

        // the API doesn't allow "", if value is emtpy it should send null
        const sanitizedData = sanitizeData(data);

        options.variables = {
          input: sanitizedData,
        };

        // console.log("save itinerary", { options });
        const response = await upsertItinerary({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          ...response,
          unpublishedChangesExist: true,
        });
        return response;
      } catch (error) {
        setError({
          type: "save",
          message: error,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [client, id, itinerary]
  );

  const saveLocation = useCallback(
    async (location, options = {}) => {
      try {
        setError(null);
        setIsSaving(true);
        let itineraryId = location?.itineraryId || itinerary?.itineraryId || id;
        if (!itineraryId) {
          // create
          const itinerary = await save();
          itineraryId = itinerary.itineraryId;
        }

        // the API doesn't allow "", if value is emtpy it should send null in order to be remove
        const sanitizedData = sanitizeData(location);

        const data = {
          itineraryId,
          locationVisited: {
            ...sanitizedData,
          },
        };

        options.variables = {
          input: data,
        };

        const response = await upsertLocation({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          locationsVisited: response?.locationsVisited,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "save",
          message: error,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [client, id, itinerary, save]
  );

  const saveLocationsBatch = useCallback(
    async (locations, options = {}) => {
      try {
        setError(null);
        setIsSaving(true);
        let itineraryId = location?.itineraryId || itinerary?.itineraryId || id;
        if (!itineraryId) {
          // create
          const itinerary = await save();
          itineraryId = itinerary.itineraryId;
        }

        const locationsData = locations.map((location) => {
          const sanitizedData = sanitizeData(location);
          const data = {
            itineraryId,
            locationVisited: {
              ...sanitizedData,
            },
          };
          return data;
        });

        options.variables = {
          input: locationsData,
        };
        // console.log("saveLocationsBatch", { options });

        const response = await upsertLocationsBatch({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          locationsVisited: response?.locationsVisited,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "save",
          message: error,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [client, id, itinerary, save]
  );

  const saveLocationOrder = useCallback(
    async (locationsOrder, options = {}) => {
      try {
        setError(null);
        setIsSaving(true);

        let itineraryId = itinerary?.itineraryId;
        if (!itineraryId) {
          const itinerary = await save();
          itineraryId = itinerary.itineraryId;
        }
        const data = {
          itineraryId,
          locationsOrder,
        };
        options.variables = {
          input: removeEmpty(data),
        };
        // console.log("saveLocationOrder", { options });
        const response = await upsertLocationsOrder({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          ...response,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "save",
          message: error,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [client, itinerary, save]
  );

  const deleteLocation = useCallback(
    async (locationId, options = {}) => {
      try {
        setError(null);
        setIsDeleting(true);
        let itineraryId = itinerary?.itineraryId;
        if (!itineraryId) {
          const itinerary = await save();
          itineraryId = itinerary.itineraryId;
        }
        const data = {
          itineraryId,
          locationId,
        };
        options.variables = {
          input: removeEmpty(data),
        };
        // console.log("delete locations", { options });
        const response = await removeLocation({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          locationsVisited: response?.locationsVisited,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "delete",
          message: error,
        });
      } finally {
        setIsDeleting(false);
      }
    },
    [client, itinerary, save]
  );

  const savePlace = useCallback(
    async (input = {}, options = {}) => {
      try {
        setError(null);
        setIsSaving(true);
        let itineraryId = input.itineraryId || itinerary?.itineraryId || id;
        if (!input.locationId) {
          return;
        }

        options.variables = {
          input: {
            ...input,
            itineraryId,
          },
        };

        // console.log("savePlace", { options });
        const response = await upsertPlace({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          locationsVisited: response?.locationsVisited,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "save",
          message: error,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [client, id, itinerary]
  );

  const deletePlace = useCallback(
    async (input = {}, options = {}) => {
      try {
        setError(null);
        setIsDeleting(true);
        let itineraryId = input.itineraryId || itinerary?.itineraryId || id;
        if (!itineraryId || !input.locationId || !input.placeId) {
          return;
        }
        const data = {
          ...input,
          itineraryId,
        };
        options.variables = {
          input: data,
        };
        // console.log("delete place", { options });
        const response = await removePlace({ client, options });
        // update itinerary with changes
        setItinerary({
          ...itinerary,
          locationsVisited: response?.locationsVisited,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "delete",
          message: error,
        });
      } finally {
        setIsDeleting(false);
      }
    },
    [client, id, itinerary]
  );

  const publish = useCallback(
    async (options = {}) => {
      try {
        setError(null);
        setIsPublishing(true);

        const itineraryId = itinerary?.itineraryId || id;
        if (!itineraryId) {
          setError("error: publising itinerary without data");
          return;
        }

        options.variables = {
          itineraryId,
        };

        // console.log("publishing itinerary", { options });
        await publishItinerary({ client, options });
        setItinerary({
          ...itinerary,
          published: true,
          unpublishedChangesExist: false,
        });
      } catch (error) {
        setError({
          type: "publish",
          message: error,
        });
      } finally {
        setIsPublishing(false);
      }
    },
    [client, id, itinerary]
  );

  const unpublish = useCallback(
    async (options = {}) => {
      try {
        setError(null);
        setIsUnpublishing(true);

        const itineraryId = itinerary?.itineraryId || id;
        if (!itineraryId) {
          setError("error: unpublising itinerary without data");
          return;
        }

        options.variables = {
          itineraryId,
        };

        // console.log("publishing itinerary", { options });
        await unpublishItinerary({ client, options });
        setItinerary({
          ...itinerary,
          published: false,
          unpublishedChangesExist: true,
        });
      } catch (error) {
        setError({
          type: "unpublish",
          message: error,
        });
      } finally {
        setIsUnpublishing(false);
      }
    },
    [client, id, itinerary]
  );

  useEffect(() => {
    if (!id) {
      setIsFetching(false);
      return;
    }
    fetch(id, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loading =
    isFetching || isSaving || isDeleting || isPublishing || isUnpublishing;

  return useMemo(() => {
    return {
      data: itinerary,
      error,
      save,
      saveLocation,
      saveLocationsBatch,
      saveLocationOrder,
      deleteLocation,
      savePlace,
      deletePlace,
      publish,
      unpublish,
      isFetching,
      isSaving,
      isDeleting,
      isPublishing,
      isUnpublishing,
      loading,
    };
  }, [
    deleteLocation,
    deletePlace,
    error,
    isDeleting,
    isFetching,
    isPublishing,
    isSaving,
    isUnpublishing,
    itinerary,
    loading,
    publish,
    save,
    saveLocation,
    saveLocationOrder,
    saveLocationsBatch,
    savePlace,
    unpublish,
  ]);
};
