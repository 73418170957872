import PropTypes from "prop-types";
// import { withStyles } from "@mui/styles";

import { FACEBOOK_URL, INSTAGRAM_URL, PINTEREST_URL } from "@/lib/constants";
import { staticUrl } from "@/lib/utils";
import { disabledWipFeatures } from "@/config";

import { Grid, Image, Link, Nav } from "@/ui";
import FacebookLight from "../../ui/icons/FacebookLight";
import InstagramLight from "../../ui/icons/InstagramLight";
import PinterestLight from "../../ui/icons/PinterestLight";

import styles from "./styles";
import { withStyles } from "@mui/styles";

const bgPalmTree = staticUrl(
  require("../../../static/img/theme-bg-palm-dark.svg"),
);

const socialLinks = [
  {
    link: FACEBOOK_URL,
    title: "Find us on Facebook",
    icon: FacebookLight,
    target: "_blank",
    rel: "noopener",
  },
  {
    link: INSTAGRAM_URL,
    title: "Find us on Instagram",
    icon: InstagramLight,
    target: "_blank",
    rel: "noopener",
  },
  {
    link: PINTEREST_URL,
    title: "Find us on Pinterest",
    icon: PinterestLight,
    target: "_blank",
    rel: "noopener",
  },
];

const navItems = [
  { text: "About Us", link: "/about/" },
  { text: "Terms of Use", link: "/terms-of-use/" },
  { text: "Acceptable Use", link: "/acceptable-use-policy/" },
  { text: "Privacy Policy", link: "/privacy-policy/" },
  { text: "Contact", link: "/contact/" },
  { text: "How it Works", link: "/how-it-works/" },
  { text: "Press", link: "https://media.atlasguru.com/" },
];

if (!disabledWipFeatures) {
  navItems.splice(5, 0, { text: "Partnership", link: "/partnership" });
}

const Footer = ({ classes, hideNav }) => (
  <footer className={classes.root}>
    <Image
      src={bgPalmTree}
      alt="AtlasGuru Palmtree"
      className={classes.footerDecorative}
      height={85}
      width={85}
    />
    <Grid container className={`${classes.container} container`}>
      <Grid xs={12} item className={classes.row}>
        <Link href="/">
          <Image
            alt="Atlas Guru - Itineraries by travelers for  travelers"
            className={classes.logo}
            src={require("@/static/img/logo-light.svg")}
            width={105}
            height={39}
          />
        </Link>
        <div className={classes.navigation}>
          <Nav
            className={classes.socialLinks}
            color="contrast"
            navItems={socialLinks}
          />
          {!hideNav && (
            <Nav
              className={classes.bottomNav}
              color="contrast"
              navItems={navItems}
            />
          )}
        </div>
      </Grid>
    </Grid>
  </footer>
);

Footer.defaultProps = {
  classes: {},
  hideNav: false,
};

Footer.propTypes = {
  classes: PropTypes.object,
  hideNav: PropTypes.bool,
};

export default withStyles(styles)(Footer);
