import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    position: "relative",
    display: "inline-block",
  },

  video: {
    width: "100%",
    height: "100%",
    display: "inline-block",

    // skin:default
    "&$default": {},

    // skin:blue
    "&$blue": {
      "&::-webkit-media-controls": {
        filter: `
          invert(16%)
          sepia(66%)
          saturate(757%)
          hue-rotate(183deg)
          brightness(93%)
          contrast(89%)
        `,
        // backgroundImage: "none !important",

        "&-panel": {
          // filter: "invert(100%) sepia(0%) saturate(7500%) hue-rotate(79deg) brightness(105%) contrast(102%)",
        },
      },
    },
  },

  // skin:default
  default: {},

  // skin:blue
  blue: {},

  // controls
  playButton: {
    position: "absolute",
    cursor: "pointer",
    left: 0,
    top: 0,

    "&, & span": {
      height: "100%",
      width: "100%",
    },

    "& svg": {
      height: 70,
      width: 70,
      left: "50%",
      top: "50%",
      position: "absolute",
      transformOrigin: 0,
      transform: "translate(-50%, -50%)",
      fill: `${theme.palette.common.black}66`,
    },

    "&:hover": {
      "& svg": {
        fill: `${theme.palette.common.highlight}66`,
      },
    },
  },
});

export default makeStyles(styles);
