import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
  },

  legend: {},

  error: {},

  formGroup: {
    marginTop: theme.spacing(1),
  },

  checkbox: {
    margin: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,

    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
  },

  errorMsg: {
    ...theme.typography.caption,
    margin: 0,

    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
});

export default makeStyles(styles);
