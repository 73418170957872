// import dynamic from "next/dynamic";

import Header from "./common/Header";
import Footer from "./common/Footer";

import useStyles from "./globalStyles";

const Layout = ({
  children,
  classes: rootClasses = {},
  hideNav = false,
  hideFooter = false,
}) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <div className={classes.layout}>
      {!hideNav && <Header />}

      <main className={classes.mainContainer}>
        <div className={classes.mainContent}>{children}</div>
      </main>

      {!hideFooter && <Footer hideNav={hideNav} />}
    </div>
  );
};

export default Layout;
