import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    position: "relative",
    zIndex: 20,
  },

  shrink: {},

  shrinkNavigation: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(3),
      display: "flex",
      height: "100%",
    },
  },

  headerWrapper: {
    backgroundColor: theme.palette.primary.main,
  },

  container: {
    alignSelf: "center",
    margin: "0 auto",
    maxWidth: 1350,
    padding: theme.spacing(),

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },

  row: {
    alignItems: "center",
    height: "100%",
  },

  midContent: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(-2.25),
      marginLeft: theme.spacing(-2.25),
    },
  },

  // TODO: search duplicated styles, move to SearchBox component once new design is applied
  isActive: {
    [theme.breakpoints.up("md")]: {
      "& $inputWrap": {
        borderRadius: "1.3em 1.3em 0 0",
        transition: theme.transitions.create("border-radius", {
          duration: 200,
          delay: 200,
        }),
      },
    },
  },

  searchWrapper: {
    [theme.breakpoints.down("md")]: {
      margin: 0 + ` ${theme.spacing(-2)}`.repeat(3),
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },

  inputWrap: {
    [theme.breakpoints.up("md")]: {
      borderRadius: "1.3em",
      transition: theme.transitions.create("border-radius", {
        duration: 100,
        delay: 100,
      }),
    },
  },

  confirmSubContent: {
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      padding: "4em 1em",
    },
  },

  confirmSubTitle: {
    marginBottom: ".5em",
  },

  paper: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "100%",
      maxHeight: "initial",
    },
  },

  megaMenuWrapper: {
    display: "grid",
    gridTemplateRows: "0fr",
  },

  megaMenuOpened: {
    gridTemplateRows: "1fr",

    "& $megaMenuInner": {
      height: "100%",
    },
  },

  megaMenuInner: {
    height: 0,
    overflow: "clip",
    transition: theme.transitions.create("height", { duration: "600ms" }),
  },
});

export default makeStyles(styles);
