import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    height: "100%",

    "& $userAccess, & $userContent": {
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        right: 0,
        top: 0,
        display: "flex",
      },

      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(3),
        alignItems: "center",
        justifyContent: "flex-end",
        display: "flex",
        height: "100%",
      },

      [theme.breakpoints.up("lg")]: {
        justifyContent: "center",
      },
    },
  },

  userNavMenu: {
    "& $userNavContent": {
      padding: 0,
    },
  },

  userNavContent: {},

  userNavMenuItem: {
    ...theme.typography.caption,
    textTransform: "uppercase",
    minHeight: theme.spacing(5),
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
  },

  userAccess: {
    padding: `${theme.spacing()}px ${theme.spacing()}px`,
    flexDirection: "row-reverse",
    gap: theme.spacing(),

    [theme.breakpoints.up("sm")]: {
      padding: 8,
      gap: theme.spacing(1.5),
    },

    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      padding: 0,
      flexDirection: "row",
      gap: theme.spacing(3),
    },
  },

  link: {
    ...theme.typography.h4,
    color: theme.palette.primary.contrastText,
    display: "inline-block",
    textAlign: "center",
  },

  signin: {
    display: "inline-flex",
    alignItems: "center",

    "&:hover": {
      "&, & span, & svg": {
        color: `${theme.palette.grey[250]} !important`,
      },
    },
  },

  icon: {
    boxShadow: `0 0 0 1px ${theme.palette.common.white}`,
    borderRadius: "50%",
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },

  iconHelp: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    color: theme.palette.common.white,
    height: 35,
    // overflow: "hidden",
    flex: "0 0 35px",

    "&:hover": {
      backgroundColor: theme.palette.common.highlight,
      transition: theme.transitions.create("background"),
    },

    [theme.breakpoints.up("md")]: {
      position: "relative",

      "&:after": {
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderBottom: `30px solid ${theme.palette.secondary.main}`,
        content: "''",
        height: 0, // 26,
        left: "calc(50% - 20px)",
        marginTop: 10,
        position: "absolute",
        top: "100%",
        width: 0, // 26,
      },
    },
  },

  iconAvatar: {
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(1),
    },
  },

  iconUser: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 5,
    },

    "& > svg": {
      top: -2,
    },
  },

  button: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    minHeight: 38,
    padding: `${theme.spacing(0.7501875468867217)} ${theme.spacing(
      0.8333333333333334,
    )}`,

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      minHeight: 26,
      minWidth: 45,
      padding: `0 ${theme.spacing(0.5)}`,
    },
  },

  loginText: {
    ...theme.typography.button,
    whiteSpace: "nowrap",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  userContent: {
    [theme.breakpoints.down("md")]: {
      top: `${theme.spacing()}px !important`,
    },
  },

  userInfo: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
    },

    "&:hover": {
      "& img": {
        transform: "scale(1.025)",
      },
    },

    "& img": {
      transformOrigin: "0 0",
      transition: theme.transitions.create("transform", { duration: 0.2 }),
    },
  },

  ellipses: {
    display: "flex",
    justifyContent: "center",

    "& > svg": {
      margin: `0 ${theme.spacing(0.5)}`,
    },
  },

  toggle: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightLight,
    margin: `0 ${theme.spacing(0.5)}`,
    textTransform: "none",
    textDecoration: "none",
    // wordBreak: "break-word",

    "&:hover": {
      color: theme.palette.common.highlight,
    },

    [theme.breakpoints.down("md")]: {
      display: "none",
    },

    "& svg": {
      fontSize: ".7em",
    },
  },

  name: {
    paddingRight: theme.spacing(1),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: 80,
  },
});

export default makeStyles(styles);
