import { withStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";

const styles = () => ({
  root: {
    height: "100%",
    opacity: 0.47,
    overflow: "hidden",
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    zIndex: 0,
  },

  mediaWrap: {
    position: "relative",

    "&:after": {
      background:
        "linear-gradient(to bottom, rgba(240,240,236,0) 0%, rgba(240,240,236,1) 100%)",
      bottom: 0,
      content: '""',
      display: "block",
      height: 300,
      position: "absolute",
      right: 0,
      width: "100%",
    },

    "& video, & img": {
      height: "auto",
      minWidth: "100%",
      objectFit: "inherit",
      width: "auto",
    },

    "& video": {
      // height: "100vh",
      objectFit: "fill",
    },
  },
});

const MediaBg = ({
  children = null,
  className = "",
  classes = {},
  ...rest
}) => (
  <div className={`${className} ${classes.root}`} {...rest}>
    <div className={classes.mediaWrap}>{children}</div>
  </div>
);

export default withStyles(styles)(MediaBg);
