const styles = (theme) => ({
  root: {
    "&$default": {
      "& $listItem": {
        marginBottom: "1.5em",
        padding: 0,

        "&:last-child": {
          marginBottom: 0,
        },
      },

      "& $showMore": {
        marginTop: "2em",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },

        [theme.breakpoints.up("sm")]: {
          width: "50%",
        },

        [theme.breakpoints.up("lg")]: {
          width: "40%",
        },
      },
    },
  },

  // {children}
  row: {},
  showMore: {},

  // <List/> classes
  list: {},
  listItem: {},
  listItemText: {},
  listTitle: {},
  listSubTitle: {},
  listItemIcon: {},

  // variant references
  default: {},
});

export default styles;
