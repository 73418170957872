import classNames from "classnames";

import { FutureCard, Link } from "@/ui";

import CardHeader from "../Card/CardHeader";
import CardSummary from "../Card/CardSummary";
import CardContent from "../Card/CardContent";
import Hero from "./Hero";

import useStyles from "./styles";

const CardHero = ({
  as = "",
  href = "",
  title = "",
  headline = "",
  description = "",
  className = "",
  loading = "lazy",
  fetchpriority = "low",
  userProfile = null,
  covers = {},
  titleProps = {},
  cardLinkProps = {},
  children = [],
  classes: rootClasses,
}) => {
  const coverKeys = Object.keys(covers);
  const classes = useStyles({ classes: rootClasses });

  return (
    <FutureCard
      component="figure"
      className={classNames(className, classes.root)}
    >
      {userProfile !== null && (
        <CardHeader
          userProfile={userProfile}
          avatarTransformations={{
            height: 75,
            width: 75,
          }}
          classes={{
            header: classes.header,
            userWrapper: classes.userWrapper,
            userLink: classes.userLink,
            userName: classes.userName,
            avatar: classes.avatar,
          }}
        />
      )}

      <Link
        as={as}
        href={href}
        role="presentation"
        className={classes.cardLink}
        {...cardLinkProps}
      >
        {coverKeys.length > 0 && (
          <Hero
            alt={title}
            headline={headline}
            fetchpriority={fetchpriority}
            loading={loading}
            covers={covers}
            classes={{
              hero: classes.hero,
              headline: classes.headline,
              picture: classes.picture,
            }}
          />
        )}

        <figcaption className={classes.container}>
          <CardSummary
            title={title}
            titleProps={titleProps}
            description={description}
            classes={{
              summary: classes.summary,
              title: classes.title,
              description: classes.description,
            }}
          />

          <CardContent className={classes.content}>{children}</CardContent>
        </figcaption>
      </Link>
    </FutureCard>
  );
};

export default CardHero;
