import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

import styles from "./styles";

const GridMasonry = ({ children, classes = {}, columns = 3 }) => {
  const columnWrapper = {};
  const result = [];

  // create columns
  for (let i = 0; i < columns; i += 1) {
    columnWrapper[`column${i}`] = [];
  }

  // divide children into columns
  for (let i = 0; i < children.length; i += 1) {
    const columnIndex = i % columns;

    columnWrapper[`column${columnIndex}`].push(
      <div className={classes.tile} key={`tile-${i}`}>
        {children[i]}
      </div>,
    );
  }

  // wrap children in each column with a div
  for (let i = 0; i < columns; i += 1) {
    if (columnWrapper[`column${i}`].length) {
      result.push(
        <div className={classes.column} key={`column-${i}`}>
          {columnWrapper[`column${i}`]}
        </div>,
      );
    }
  }

  return <div className={classes.root}>{result}</div>;
};

GridMasonry.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default withStyles(styles)(GridMasonry);
