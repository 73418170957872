import { useCallback, useEffect, useState, useRef } from "react";
import { orderBy } from "lodash-es";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import algolia from "@/lib/algolia";
import { SEARCH_DEBOUNCE_TIME } from "@/lib/constants";
import { Button, Link, Nav, Popper, Typography } from "@/ui";

import useStyles from "./styles";

const NavTopics = (props) => {
  const {
    classes: rootClasses,
    title = "Popular Topics",
    titleTag = "h3",
    showMoreBtn = true,
    maxTopics = 20, // max items to display on the HTML code
    maxQuery = 40, // max items to query from API
    requestQuery = "",
    flip = false,
    variant = "default",
    keepTreeWhileEmpty = true,
    noResultsLegend = "", // "No results found for topics",
  } = props;
  const { search } = algolia("topics");
  const debounceAlgoliaSearch = useRef();

  const theme = useTheme();
  const isTabletDowm = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({ classes: rootClasses });

  const anchorRef = useRef(null);
  const [moreButtonEl, setMoreButtonEl] = useState(null);
  const [topics, setTopics] = useState([]);

  const showMoreTopics = showMoreBtn && topics.length > 3;
  const isPopperOpen = Boolean(moreButtonEl);
  const isEmptyTopics = topics.length < 1;
  const sortedTopics = orderBy(
    topics,
    ({ displayOrder }) => displayOrder || "",
    "desc",
  )
    .slice(0, maxTopics || topics.length)
    .map(({ topicTitle, url }) => ({
      text: topicTitle,
      link: url,
    }));

  // fetch and set current topics depends of the query
  const fetchTopics = useCallback(async () => {
    try {
      const { hits } = await search(requestQuery, { hitsPerPage: maxQuery });
      setTopics(hits);
    } catch (e) {
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestQuery]);

  const fetchWithDebounce = (time) => {
    clearTimeout(debounceAlgoliaSearch.current);

    debounceAlgoliaSearch.current = setTimeout(() => {
      fetchTopics();
    }, time);
  };

  // toggle the popper when the button is clicked
  const handleTogglePopper = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const target = evt.target || evt.currentTarget;
    setMoreButtonEl(moreButtonEl ? null : target);
  };

  // close topics popper
  const handleClosePopper = () => {
    setMoreButtonEl(null);
  };

  // get all topics when component load
  useEffect(() => {
    if (!requestQuery) {
      fetchTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // debounce related topics query when requestQuery changes
  useEffect(() => {
    if (requestQuery) {
      fetchWithDebounce(SEARCH_DEBOUNCE_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestQuery]);

  if (isEmptyTopics && !keepTreeWhileEmpty) return null;

  return (
    <div className={`${classes.root} ${classes[variant]}`}>
      <div
        className={`${classes.container} ${
          isEmptyTopics ? classes.emptyContainer : ""
        }`}
      >
        <div className={classes.navigationWrapper}>
          <div className={classes.navigationInner}>
            <div className={classes.navigationEllipsis}>
              {title && (
                <Typography
                  classes={{ root: classes.title }}
                  component={titleTag}
                  variant="h4"
                >
                  {title}
                </Typography>
              )}

              {topics.length > 0 && (
                <Nav
                  classes={{
                    root: classes.navigation,
                    link: classes.link,
                    text: classes.text,
                  }}
                  navItems={sortedTopics}
                />
              )}

              {isEmptyTopics && noResultsLegend && (
                <Typography
                  classes={{ root: classes.navigation }}
                  component="nav"
                  variant="body2"
                >
                  {noResultsLegend}
                </Typography>
              )}
            </div>

            {showMoreTopics && (
              <div className={classes.buttonWrapper} ref={anchorRef}>
                <Button className={classes.button} onClick={handleTogglePopper}>
                  See More
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {showMoreTopics && (
        <Popper
          disablePortal
          className={classes.moreTopicsPopper}
          anchorEl={anchorRef.current}
          onClose={handleClosePopper}
          placement={isTabletDowm ? "bottom" : "bottom-end"}
          open={isPopperOpen}
          flip={flip}
        >
          <div className={classes.popperWrapper}>
            <Typography
              classes={{ root: classes.popperTitle }}
              component="h4"
              variant="subtitle1"
            >
              More Topics
            </Typography>

            <div className={classes.popperList}>
              {topics.map(({ topicTitle, url, topicId }) => (
                <Link
                  key={topicId}
                  href={url}
                  onClick={handleClosePopper}
                  className={classes.popperLink}
                >
                  {topicTitle}
                </Link>
              ))}
            </div>
          </div>
        </Popper>
      )}
    </div>
  );
};

export default NavTopics;
