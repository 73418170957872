import { Box, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { IconSearch } from "@/icons";

import { getPlaceValue } from "./utils";
import useGooglePlaces from "./useGooglePlaces";
import Option from "./Option";
import Input from "./Input";
import useStyles from "./styles";

function GooglePlace({
  label = "",
  className = "",
  locationType = "",
  initialValue = "",
  disabled = false,
  minCharsForApiRequest = 3,
  onInputChange = () => {},
  onChange = () => {},
  inputProps,
  clearOnSelect,
  ...props
}) {
  const classes = useStyles({ classes: props.classes });
  const { options, inputValue, handleSelectedChange, handleInputChange } =
    useGooglePlaces({
      minCharsForApiRequest,
      initialValue,
      locationType,
      onInputChange,
      onChange,
      clearOnSelect,
    });

  return (
    <div
      className={`${className} ${classes.root} ${
        disabled ? classes.disabled : ""
      }`}
    >
      <Autocomplete
        freeSolo
        fullWidth
        autoComplete
        selectOnFocus
        disablePortal // using portal the Popper renders in the wrong position
        includeInputInList
        filterSelectedOptions
        options={options}
        value={inputValue}
        disabled={disabled}
        filterOptions={(x) => x}
        onChange={handleSelectedChange}
        onInputChange={handleInputChange}
        classes={{ popper: classes.popper, option: classes.option }}
        getOptionDisabled={(option) => option.disabled}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : getPlaceValue(option)
        }
        renderOption={(rootProps, option) => {
          const { key, ...optionProps } = rootProps;
          return (
            <li key={key} {...optionProps}>
              <Option classes={props.classes} option={option} />
            </li>
          );
        }}
        renderInput={(params) => (
          <Box
            sx={{
              " > input": {
                padding: 0,
              },
            }}
          >
            <Input
              label={label}
              classes={props.classes}
              endAdornment={
                <IconSearch className={classes.inputIcon} viewBox="0 0 28 28" />
              }
              {...inputProps}
              {...params}
            />
          </Box>
        )}
        {...props}
      />

      {inputProps.egText && (
        <FormHelperText
          // id={`field-info-${name}`}
          className={classes.egText}
          component="span"
        >
          {inputProps.egText}
        </FormHelperText>
      )}
    </div>
  );
}

export default GooglePlace;
