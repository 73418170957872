import { styled } from "@mui/styles";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";
import { IconCheck, IconCircle, IconSquareChecked, IconSquare } from "@/icons";

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, variant }) => ({
    "&.MuiFormControlLabel-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    // variant="button"
    ...(variant === "button" && {
      "&.MuiFormControlLabel-root": {
        position: "relative",
        // margin: 0,

        "&.Mui-error": {
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,

          "& .MuiCheckbox-root": {
            "&.Mui-checked": {
              "& + .MuiFormControlLabel-label": {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
              },
            },
          },
        },

        "& .MuiFormControlLabel-label": {
          ...theme.typography.subtitle2,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          lineHeight: "1.2em",
          margin: 0,
          minWidth: "4em",
          padding: "1em",
          position: "relative",
          textAlign: "center",
          transition: theme.transitions.create("all"),
          zIndex: 10,
        },

        "& .MuiFormControlLabel-label:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },

        "& .MuiCheckbox-root": {
          height: "100%",
          position: "absolute",
          width: "100%",
          display: "none",

          "&.Mui-checked": {
            "& + .MuiFormControlLabel-label": {
              backgroundColor: theme.palette.common.highlight,
              color: theme.palette.common.white,
            },
          },

          "& > span": {
            opacity: 0,
          },
        },
      },
    }),

    // variant="check"
    ...(variant === "check" && {
      "&.MuiFormControlLabel-root": {
        padding: 0,

        "& .icon > svg": {
          color: "inherit",
        },

        "& .MuiCheckbox-root": {
          padding: 0,

          "&.Mui-checked .icon circle": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    }),

    // variant="square"
    ...(variant === "square" && {
      "&.MuiFormControlLabel-root": {
        padding: 0,

        "&.Mui-error": {
          "& .icon > svg": {
            color: theme.palette.error.main,
          },
        },

        "& .icon > svg": {
          color: theme.palette.common.highlight,
        },

        "& .MuiFormControlLabel-label": {
          ...theme.typography.subtitle2,
        },

        "& .MuiCheckbox-root": {
          padding: 0,
        },

        "& .Mui-checked .icon circle": {
          fill: theme.palette.secondary.main,
        },
      },
    }),
  })
);

const Checkbox = ({
  className = "",
  classes = {},
  error = "",
  label = "",
  name = "",
  onFocus = () => {},
  required = false,
  variant = "default",
  value,
  ...rest
}) => {
  let customProps = rest;

  if (variant === "check") {
    customProps = {
      ...customProps,
      icon: <IconCircle />,
      checkedIcon: <IconCheck />,
    };
  }

  if (variant === "square") {
    customProps = {
      ...customProps,
      icon: <IconSquare />,
      checkedIcon: <IconSquareChecked />,
    };
  }

  return (
    <StyledFormControlLabel
      classes={{
        label: classes.label,
        root: `${className} ${classes.root} ${classes[variant]} ${classes.error}`,
      }}
      error={!!error}
      control={
        <MuiCheckbox
          aria-describedby={`checkbox-info-${name}`}
          classes={{
            root: classes.checkbox,
            checked: classes.checked,
          }}
          name={name}
          onFocus={onFocus}
          required={required}
          value={value}
          color="primary"
          {...customProps}
        />
      }
      label={label || ""}
      required={required}
      variant={variant}
    />
  );
};

export default Checkbox;
