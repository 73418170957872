import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IconArrow } from "./icons";

const styles = (theme) => ({
  root: {
    padding: 0,
  },

  // variant="default"
  default: {},

  // variant="transparent"
  transparent: {
    "& $expansion": {
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },

  // variant="standard"
  standard: {},

  // children
  expansion: {
    // backgroundColor: "#F0F1EC"
  },

  summary: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },

    "&, &$expanded": {
      minHeight: 0,
      padding: 0,
    },

    "&$expanded": {
      backgroundColor: "rgba(153, 161, 173, 0.2)",
    },
  },

  expanded: {},

  content: {
    maxWidth: "100%",
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,

    "&, &$expanded": {
      margin: 0,
      minHeight: 0,
    },

    "& > :last-child": {
      paddingRight: theme.spacing(3),
    },
  },

  expandIcon: {
    opacity: 0.5,
    margin: 0,
    padding: 0,
    marginRight: theme.spacing(2),

    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  details: {
    padding: theme.spacing(1),
  },
});

const Collapsible = ({
  classes,
  children,
  count,
  onChange,
  summary,
  variant,
  ...rest
}) => (
  <div className={`${classes.root} ${classes[variant]}`}>
    <Accordion
      classes={{ root: classes.expansion }}
      onChange={onChange}
      square
      {...rest}
    >
      {summary && (
        <AccordionSummary
          aria-controls={`panel-${count}-content`}
          classes={{
            root: classes.summary,
            content: classes.content,
            expandIconWrapper: classes.expandIcon,
            expanded: classes.expanded,
          }}
          expandIcon={<IconArrow variant="down" />}
          id={`panel-${count}-content`}
        >
          {summary}
        </AccordionSummary>
      )}
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  </div>
);

Collapsible.defaultProps = {
  classes: {},
  count: 1,
  onChange: () => {},
  summary: "",
  variant: "default",
};

Collapsible.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  onChange: PropTypes.func,
  summary: PropTypes.node,
  variant: PropTypes.string,
};

export default withStyles(styles)(Collapsible);
