import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import { IconClose } from "../icons";

const styles = (theme) => ({
  root: {
    ...theme.typography.subtitle2,
    borderRadius: 0,

    "& svg": {
      fill: theme.palette.common.white,
      stroke: theme.palette.common.white,
    },
  },

  success: {
    backgroundColor: theme.palette.secondary.main,
  },

  error: {
    backgroundColor: theme.palette.error.dark,
  },

  info: {
    backgroundColor: theme.palette.primary.main,
  },

  warning: {
    backgroundColor: theme.palette.common.highlight,
  },

  icon: {
    color: theme.palette.common.white,
    fontSize: 20,
  },

  iconClose: {},

  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },

  message: {},

  messageContent: {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
});

const SnackbarContentWrapper = ({
  classes,
  className,
  message,
  onClose,
  variant,
  ...other
}) => (
  <SnackbarContent
    className={`${classes.root} ${classes[variant]} ${className}`}
    classes={{ message: classes.message }}
    aria-describedby="client-snackbar"
    message={
      <div id="client-snackbar" className={classes.messageContent}>
        {message}
      </div>
    }
    action={[
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={onClose}
        size="large"
      >
        <IconClose className={classes.iconClose} size="sm" />
      </IconButton>,
    ]}
    {...other}
  />
);

SnackbarContentWrapper.defaultProps = {
  className: "",
  classes: {},
  message: "",
  onClose: () => {},
  variant: "info",
};

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]),
};

export default withStyles(styles)(SnackbarContentWrapper);
