const styles = (theme) => ({
  paperRoot: {
    backgroundColor: "transparent",
    padding: "2em",
  },

  root: {
    backgroundColor: "transparent",
    padding: 0,
    width: "100%",
  },

  // variant={default}
  default: {},

  // variant={primary}
  primary: {
    "& $step": {
      cursor: "pointer",
    },

    "& $connector": {
      left: "calc(-50% + 1em)",
      opacity: 0.5,
      right: "calc(50% + 1em)",
      top: "1.15em",
      zIndex: "0",

      [theme.breakpoints.up("sm")]: {
        top: "1.35em",
      },
    },

    "& $connectorLine": {
      borderColor: theme.palette.primary.main,
      borderTopWidth: 2,
    },

    "& $labelRoot": {
      position: "relative",
      zIndex: "10",
    },

    "& $iconContainer": {
      "& $iconSvg": {
        ...theme.typography.h2,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "50%",
        color: theme.palette.grey[100],
        fontSize: "inherit",
        height: "1.4705em",
        width: "1.4705em",

        "&$iconActive": {
          color: theme.palette.common.highlight,
          borderColor: "transparent",

          "& $iconText": {
            fill: theme.palette.common.white,
          },
        },

        "&$iconCompleted": {
          color: theme.palette.primary.main,
          borderColor: "transparent",
        },
      },

      "& $iconText": {
        fill: theme.palette.grey[300],
        fontFamily: theme.atlasGuru.fonts[1],
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: "1em",
      },
    },

    "& $iconLoading": {
      marginLeft: theme.spacing(),
    },

    "& $label": {
      ...theme.typography.h6,
      color: theme.palette.grey[300],
      marginTop: "0.6em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "&$labelActive": {
        color: theme.palette.common.highlight,
      },

      "&$labelCompleted": {
        color: theme.palette.primary.main,
      },
    },
  },

  // {children}
  step: {},
  connector: {},
  connectorLine: {},
  labelRoot: {},
  label: {},
  labelActive: {},
  labelCompleted: {},
  iconActive: {},
  iconCompleted: {},
  iconContainer: {},
  iconLoading: {},
  iconSvg: {},
  iconText: {},
});

export default styles;
