import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserData on User {
    isAdmin
    userId
    email
    bio
    firstName
    screenName
    lastName
    homeCity
    aiItineraryUsage {
      usageCount
      usageLimit
    }
    cityAdvice {
      formatted_address
      originalName
      name
    }
    dreamVacation
    profilePicUrl
    facebookProfile
    twitterProfile
    instagramProfile
    pinterestProfile
    tiktokProfile
    cloudId
    coverPicUrl
    websiteUrl
    creationDate
    marketingEmailConsent
    tripHelpEmailConstent
    partner {
      name
      id
      logo
    }
  }
`;

export const USER_QUERY = gql`
  query USER_QUERY($userId: ID, $screenName: String, $email: String) {
    User(userId: $userId, screenName: $screenName, email: $email) {
      ...UserData
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_QUERY_NO_CACHE = gql`
  query USER_QUERY_NO_CACHE($userId: ID, $screenName: String, $email: String) {
    User(userId: $userId, screenName: $screenName, email: $email) {
      ...UserData
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_MUTATION = gql`
  mutation UPSERT_USER($input: UserInput!) {
    upsertUser(input: $input) {
      ...UserData
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_ADD_ITINERARIES = gql`
  mutation UpsertItinerariesToUser($input: [ID]!) {
    upsertItinerariesToUser(input: $input)
  }
`;
