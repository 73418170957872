import { makeStyles } from "@mui/styles";

const heroAvatarWidth = 170;

const styles = (theme) => ({
  root: {},

  figure: {
    margin: 0,
    padding: 0,
    display: "block",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      margin: `0 ${theme.spacing(-3)} 0`,
    },

    "& picture": {
      position: "relative",
      zIndex: 10,
    },
  },

  media: {
    position: "relative",
  },

  picture: {
    "& img": {
      aspectRatio: "3/4",

      [theme.breakpoints.up("sm")]: {
        aspectRatio: "16/9",
      },
    },
  },

  noImageSet: {
    [theme.breakpoints.down("md")]: {
      aspectRatio: "3/4",

      "& img": {
        objectFit: "cover",
        height: "100%",
        width: "100%",
      },
    },
  },

  playButton: {
    "& svg": {
      transform: "scale(1) translate(-50%, -100%)",

      [theme.breakpoints.up("sm")]: {
        transform: "scale(1.6) translate(-50%, -80%)",
      },
    },
  },

  headline: {
    ...theme.typography.jumbo,
    color: theme.palette.common.white,
    textShadow: `1px 1px 5px ${theme.palette.common.black}99`,
    backgroundColor: `${theme.palette.common.black}33`,
    padding: "1em 1em 2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20,
  },

  card: {
    margin: `${theme.spacing(-10)} ${theme.spacing(3)} 0 !important`,
    padding: "0 !important",
    position: "relative",
    overflow: "unset",
    zIndex: 30,

    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(-14)} ${theme.spacing(5.5)} 0 !important`,
    },
  },

  cardContent: {
    margin: 0,
    padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(4)}`,
  },

  cardContentWithUser: {
    paddingTop: theme.spacing(7),

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: `${theme.spacing(5)} !important`,
      paddingLeft: theme.spacing(),
    },
  },

  content: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: theme.spacing(1.5),
    },
  },

  userProfile: {
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: 0,
      left: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(6)})`,
      marginTop: "-1em",
    },

    [theme.breakpoints.up("md")]: {
      width: heroAvatarWidth,
    },
  },

  userLink: {
    color: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: theme.spacing(1.5),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      gap: 0,
      padding: 0,
    },

    "&:hover": {
      "& img": {
        transform: "scale(1.2)",
      },

      "& $userLinkInfo": {
        color: theme.palette.common.highlight,
      },
    },
  },

  userLinkInfo: {
    fontSize: `${theme.atlasGuru.fontSizes.subtitle2.xs}px !important`,
    display: "inline-block",
    maxWidth: "90%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    [theme.breakpoints.down("md")]: {
      transform: "translateY(0.2em)",
    },
  },

  avatar: {
    height: 54,
    width: 54,
    boxSizing: "initial",

    [theme.breakpoints.up("md")]: {
      height: 74,
      width: 74,
    },

    "& img": {
      transform: "scale(1)",
      transformOrigin: "50%",
      transition: theme.transitions.create("transform", {
        duration: 200,
        easing: theme.transitions.easing.easeIn,
      }),
    },
  },

  info: {
    flex: 1,

    "& > a": {
      "& $userLink, & $headline": {
        transition: theme.transitions.create("color"),
      },

      "&:hover": {
        "& $userLink, & $headline": {
          color: theme.palette.common.highlight,
        },
      },
    },
  },
});

export default makeStyles(styles);
