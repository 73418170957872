import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { IconClose } from "../icons";
import Dialog from "../Dialog";
import DialogTitle from "../DialogTitle";
import Grid from "../Grid";
import IconButton from "../IconButton";
import Typography from "../Typography";

import styles from "./styles";

const Modal = ({
  classes,
  children,
  fullScreen,
  onClose,
  open,
  title,
  showCloseBtn,
  variant,
  disableEscapeKeyDown = false,
  IconCloseButton,
  ...rest
}) => (
  <Dialog
    classes={{
      container: `${classes.root} ${classes[variant]} ${
        fullScreen ? classes.fullScreen : ""
      }`,
      paperScrollPaper: classes.paperScrollPaper,
      paper: classes.paper,
    }}
    fullScreen={fullScreen}
    onClose={onClose}
    open={open}
    disableEscapeKeyDown={disableEscapeKeyDown}
    {...rest}
  >
    <Grid container id="modal-container" className={classes.modalContainer}>
      <Grid item xs={12} className={classes.innerContainer}>
        {showCloseBtn && (
          <IconButton
            className={classes.closeBtn}
            onClick={onClose}
            size="large"
          >
            <IconCloseButton size="md" viewBox="-6 -6 212 212" />
          </IconButton>
        )}
        <Grid container className={`${classes.content} container`}>
          <Grid item xs={12} className={classes.row}>
            {title && (
              <DialogTitle className={classes.header}>
                <Typography
                  classes={{ root: classes.title }}
                  component="h6"
                  variant="h2"
                >
                  {title}
                </Typography>
              </DialogTitle>
            )}
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Dialog>
);

Modal.defaultProps = {
  classes: {},
  fullScreen: false,
  onClose: () => {},
  open: false,
  title: "",
  showCloseBtn: true,
  IconCloseButton: IconClose,
  variant: "default",
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  showCloseBtn: PropTypes.bool,
  variant: PropTypes.string,
};

export default withStyles(styles)(Modal);
