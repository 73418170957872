import { makeStyles } from "@mui/styles";

const styles = () => ({
  root: {
    // https://web.dev/learn/design/responsive-images/
    aspectRatio: "attr(width) / attr(height)",
    maxInlineSize: "100%",
    objectFit: "contain",
    blockSize: "auto",
    // height: "auto",
  },
});

export default makeStyles(styles);
