const styles = (theme) => ({
  root: {
    "& > $disabled": {
      "&, & > input, & > textarea, & legend": {
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[50],

        "&:-webkit-autofill": {
          boxShadow: `0 0 0px 1000px ${theme.palette.grey[50]} inset`,
        },
      },
    },

    "& $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
  },

  marginDense: {
    // marginTop: theme.gutter,

    "& .icon > svg": {
      height: "1.6em",
      width: "1.6em",
    },
  },

  adornedEnd: {
    "& .icon": {
      color: theme.palette.grey[700],
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
  },

  adornedStart: {
    "& .icon": {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },

  formControl: {},

  label: {
    ...theme.typography.subtitle2,
    marginTop: "-2px",
    marginLeft: "-2px",
    padding: theme.spacing(0.6),
    lineHeight: 1,
    textAlign: "left",

    "&$disabled": {
      color: theme.palette.grey[300],
    },
  },

  labelShrink: {
    fontFamily: theme.atlasGuru.fonts[2],
    fontSize: "12px !important",
    // padding: theme.spacing(0.6),
    lineHeight: 1,
  },

  labelMarginDense: {
    ...theme.typography.subtitle2,
  },

  outlined: {
    "&$labelShrink": {
      color: theme.palette.common.black,
      marginLeft: theme.spacing(-0.4),

      "&$labelMarginDense": {
        fontFamily: theme.atlasGuru.fonts[2],
        transform: `
          translate(${theme.spacing(1.75)}, ${theme.spacing(-1)}) 
          scale(0.82)
        `,
      },
    },

    "&$labelMarginDense": {
      transform: `
        translate(${theme.spacing(1.75)}, ${theme.spacing(1.75)}) 
        scale(1)
      `,
    },
  },

  asterisk: {
    color: theme.palette.error.main,
  },

  rounded: {
    "& fieldset, & $input": {
      borderRadius: theme.spacing(),
    },
  },

  notchedOutline: {
    borderRadius: 0,
    overflow: "inherit",
    top: theme.spacing(-1),

    "& > legend": {
      background: theme.palette.common.white,
      lineHeight: theme.spacing(2),
      position: "relative",
      top: theme.spacing(-0.8),
    },
  },

  notchedOutlineEdge: {
    top: 0,

    "& > legend": {
      background: theme.palette.common.white,
      lineHeight: theme.spacing(2),
      position: "relative",
      top: theme.spacing(1) * -1.4,
    },
  },

  input: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
    paddingRight: theme.spacing(1.2),
  },

  disabled: {},

  inputElem: {
    ...theme.typography.subtitle2,
    color: theme.palette.common.black,
    backgroundColor: "transparent",
    lineHeight: "1.4em",
  },

  inputMarginDense: {
    ...theme.typography.subtitle2,
  },

  multiline: {
    "&$inputMarginDense": {
      padding: `${theme.spacing(2.31)} ${theme.spacing(1.75)}`,
    },
  },

  textarea: {
    resize: "vertical !important",
    overflowY: "overflow !important",
  },

  inputError: {
    color: theme.palette.error.main,
  },

  icon: {
    "& svg": {
      height: "1.2em",
      width: "1.2em",
    },
  },

  formControFooter: {
    display: "flex",
    marginTop: theme.spacing(0.5),

    "& > span": {
      minHeight: "1em",
    },
  },

  helperText: {
    ...theme.typography.caption,
    color: theme.palette.primary.main,
    display: "block",
    lineHeight: "1.4",
    margin: "0 0 0 auto",
  },

  egText: {
    ...theme.typography.caption,
    color: theme.palette.grey[750],
    display: "block",
    lineHeight: "1.4",
    margin: 0,
  },

  errorMsg: {
    ...theme.typography.caption,
    lineHeight: 1.2,
    display: "block",
    margin: 0,
  },
});

export default styles;
