import { makeStyles } from "@mui/styles";

const styles = () => ({
  inputRoot: {
    marginBottom: 0,
  },

  formControl: {
    marginBottom: 0,

    "& small": {
      marginTop: 0,
    },

    "& span": {
      minHeight: 0,
    },
  },

  formControFooter: {
    "&, & small": {
      height: "0 !important",
    },
  },
});

export default makeStyles(styles);
