import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Select as MuiSelect, MenuItem, OutlinedInput } from "@mui/material";
import { IconArrow } from "./icons";
// import Input from "./Input";

const styles = (theme) => ({
  root: {
    display: "block",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },

  select: {
    // padding: "0.6em",

    "&:focus": {
      backgroundColor: "transparent",
    },

    backgroundColor: "transparent",
    paddingLeft: theme.spacing(6),
    position: "relative",
    zIndex: 10,
  },

  input: {
    height: "3.2em",
    borderRadius: 0,

    "& > .icon svg": {
      height: "1.6em",
      width: "1.6em",
    },
  },

  icon: {
    marginTop: "-0.5em",
    position: "absolute",
    right: theme.spacing(2),
    top: "50%",
  },

  filled: {},

  outlined: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
  },
});

const Select = ({
  autoWidth,
  classes,
  error,
  fullWidth,
  inputProps,
  margin,
  native,
  options,
  selected,
  variant,
  ...rest
}) => {
  const renderOptions = (option) => {
    const { label, ...restOption } = option;
    const ItemComponent = native ? "option" : MenuItem;

    return (
      <ItemComponent key={option.value} {...restOption}>
        {label}
      </ItemComponent>
    );
  };

  // TODO: remove MuiOutlined button and use ./Input component

  // const renderInput = () => (
  //   <Input
  //     classes={{
  //       ...inputProps.classes,
  //       root: classes.input
  //     }}
  //     endAdornment={<IconArrow variant="down" />}
  //     error={error || ""}
  //     // value={value}
  //     variant={variant}
  //     {...inputProps}
  //   />
  // );

  return (
    <MuiSelect
      autoWidth={autoWidth}
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
        filled: classes.filled,
        outlined: classes.outlined,
      }}
      // disableUnderline
      fullWidth={fullWidth}
      // input={renderInput()}
      input={<OutlinedInput classes={{ root: classes.input }} error={error} />}
      IconComponent={() => (
        <IconArrow classes={{ root: classes.icon }} variant="down" />
      )}
      inputProps={inputProps}
      margin={margin}
      native={native}
      value={selected}
      variant={variant}
      {...rest}
    >
      {options.map(renderOptions)}
    </MuiSelect>
  );
};

Select.defaultProps = {
  autoWidth: false,
  classes: {},
  error: false,
  fullWidth: true,
  // inputProps: { classes: {} },
  margin: "none",
  native: false,
  options: [{ label: "Add Options...", disabled: true, value: "" }],
  selected: "",
  variant: "standard",
};

Select.propTypes = {
  autoWidth: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  // inputProps: PropTypes.object,
  margin: PropTypes.string,
  native: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
};

export default withStyles(styles)(Select);
