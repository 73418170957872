import { Snackbar as MuiSnackbar } from "@mui/material";
import SnackbarContentWrapper from "./SnackbarContentWrapper";

const Snackbar = ({
  autoHideDuration = 4000,
  className = "",
  classes = {},
  message = "",
  open = false,
  setOpen = () => {},
  anchorOrigin = { vertical: "bottom", horizontal: "center" },
  variant,
  ...rest
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      {...rest}
    >
      <SnackbarContentWrapper
        classes={classes}
        className={className}
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </MuiSnackbar>
  );
};

export default Snackbar;
