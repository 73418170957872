import { memo, useCallback, useRef, useState } from "react";
import { delay } from "lodash-es";

import { algoliaIndexNameItinerary } from "@/config";
import { Grid } from "@/ui";

import SearchBox from "../SearchBox";
import Brand from "./Brand";
import Navigation from "./Navigation";
import ShrinkNav from "./Navigation/ShrinkNav";
import MegaNav from "./Navigation/MegaNav";
import UserMenu from "./UserMenu";

import useStyles from "./styles";

const Header = ({ shrink, setIsStickyActive }) => {
  const classes = useStyles();
  const [openMegaMenu, setOpenMegaMenu] = useState("");
  const [isMegaMenuVisible, setIsMegaMenuVisible] = useState(false);
  const megaMenuWrapperRef = useRef(null);

  const toggleMegaMenu = useCallback(
    (id = "") => {
      setIsMegaMenuVisible(true);
      // run animation in next render
      requestAnimationFrame(() => {
        setOpenMegaMenu(openMegaMenu ? "" : id);
      });
      delay(() => setIsStickyActive((sticky) => !sticky), 600);
    },
    [openMegaMenu, setIsStickyActive]
  );

  const onClickNavItem = (e, { id, hasSubMenu } = {}) => {
    if (hasSubMenu) {
      e.preventDefault();
      toggleMegaMenu(id);
    }
  };

  const onTransitionEnd = () => {
    if (
      !megaMenuWrapperRef.current.className.includes(classes.megaMenuOpened)
    ) {
      setIsMegaMenuVisible(false);
    }
  };

  return (
    <div className={shrink && !openMegaMenu ? classes.shrink : ""}>
      <div className={classes.headerWrapper}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={3}>
            <div className={classes.shrinkNavigation}>
              <ShrinkNav shrink={shrink} />
              <Brand shrink={shrink} />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid className={classes.midContent}>
              <Grid container className={classes.row}>
                <Grid item xs={12}>
                  <SearchBox
                    indexName={algoliaIndexNameItinerary}
                    classes={{
                      isActive: classes.isActive,
                      searchWrapper: classes.searchWrapper,
                      inputWrap: classes.inputWrap,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Navigation
                    shrink={shrink}
                    openMegaMenu={openMegaMenu}
                    onClickNavItem={onClickNavItem}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <UserMenu />
          </Grid>
        </Grid>
      </div>

      {isMegaMenuVisible && (
        <div
          ref={megaMenuWrapperRef}
          className={`${classes.megaMenuWrapper} ${
            openMegaMenu ? classes.megaMenuOpened : ""
          }`}
        >
          <div
            className={`${classes.megaMenuInner}`}
            onTransitionEnd={onTransitionEnd}
          >
            <Grid container>
              <Grid item xs={12}>
                <MegaNav
                  openMegaMenu={openMegaMenu}
                  toggleMegaMenu={toggleMegaMenu}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Header);
