import ArrowThin from "@/icons/ArrowThin";

export const headerNav = [
  {
    id: "megaMenu",
    text: "Explore Itineraries",
    link: "/itineraries/",
    hasSubMenu: true,
    icon: ArrowThin,
  },
  { text: "Contributors", link: "/contributors/" },
  { text: "Write Report", link: "/authoring/" },
  { text: "How it Works", link: "/how-it-works/" },
  {
    text: "AI Planner",
    link: "/ai-itinerary-planner/",
    tag: "New",
  },
];

export const megaNav = {
  locations: {
    items: {
      europe: {
        title: "Europe",
        url: "/itineraries/europe/",
        items: {
          al: { label: "Albania", url: "/results/?term=Albania&page=1" },
          at: { label: "Austria", url: "/results/?term=Austria&page=1" },
          be: { label: "Belgium", url: "/results/?term=Belgium&page=1" },
          ba: { label: "Bosnia", url: "/results/?term=Bosnia&page=1" },
          bg: { label: "Bulgaria", url: "/results/?term=Bulgaria&page=1" },
          hr: { label: "Croatia", url: "/results/?term=Croatia&page=1" },
          cz: { label: "Czechia", url: "/results/?term=Czechia&page=1" },
          dk: { label: "Denmark", url: "/results/?term=Denmark&page=1" },
          gb: { label: "England", url: "/results/?term=England&page=1" },
          ee: { label: "Estonia", url: "/results/?term=Estonia&page=1" },
          // fo: {
          //   label: "Faroe Islands",
          //   url: "/results/?term=Faroe%20Islands&page=1",
          // },
          fi: {
            label: "Finland",
            url: "/results/?term=Finland%20Europe&page=1",
          },
          fr: { label: "France", url: "/results/?term=France&page=1" },
          de: { label: "Germany", url: "/results/?term=Germany&page=1" },
          gr: { label: "Greece", url: "/results/?term=Greece&page=1" },
          hu: { label: "Hungary", url: "/results/?term=Hungary&page=1" },
          is: { label: "Iceland", url: "/results/?term=Iceland&page=1" },
          ie: {
            label: "Ireland",
            url: "/results/?term=Ireland%20Europe&page=1",
          },
          it: { label: "Italy", url: "/results/?term=Italy&page=1" },
          lv: { label: "Latvia", url: "/results/?term=Latvia&page=1" },
          lt: { label: "Lithuania", url: "/results/?term=Lithuania&page=1" },
          mt: { label: "Malta", url: "/results/?term=Malta&page=1" },
          mc: { label: "Monaco", url: "/results/?term=Monaco&page=1" },
          me: { label: "Montenegro", url: "/?term=Montenegro%20Europe&page=1" },
          nl: {
            label: "Netherlands",
            url: "/results/?term=Netherlands&page=1",
          },
          mk: {
            label: "North Macedonia",
            url: "/results/?term=North%20Macedonia&page=1",
          },
          no: { label: "Norway", url: "/results/?term=Norway&page=1" },
          pl: { label: "Poland", url: "/results/?term=Poland&page=1" },
          pt: { label: "Portugal", url: "/results/?term=Portugal&page=1" },
          ro: { label: "Romania", url: "/results/?term=Romania&page=1" },
          ru: { label: "Russia", url: "/results/?term=Russia&page=1" },
          si: { label: "Slovenia", url: "/results/?term=Slovenia&page=1" },
          es: { label: "Spain", url: "/results/?term=Spain&page=1" },
          se: { label: "Sweden", url: "/results/?term=Sweden&page=1" },
          ch: {
            label: "Switzerland",
            url: "/results/?term=Switzerland&page=1",
          },
          gb_sc: { label: "Scotland", url: "/results/?term=Scotland&page=1" },
          tr: { label: "Turkey", url: "/results/?term=Turkey&page=1" },
          ua: { label: "Ukraine", url: "/results/?term=Ukraine&page=1" },
          gb_uk: {
            label: "United Kingdom",
            url: "/results/?term=United%20Kingdom&page=1",
          },
          va: {
            label: "Vatican City",
            url: "/results/?term=Vatican%20City&page=1",
          },
        },
      },
      south_america: {
        title: "South America",
        url: "/itineraries/south-america/",
        items: {
          ar: { label: "Argentina", url: "/results/?term=Argentina&page=1" },
          bo: { label: "Bolivia", url: "/results/?term=Bolivia&page=1" },
          br: { label: "Brazil", url: "/results/?term=Brazil&page=1" },
          cl: { label: "Chile", url: "/results/?term=Chile&page=1" },
          co: { label: "Colombia", url: "/results/?term=Colombia&page=1" },
          ec: { label: "Ecuador", url: "/results/?term=Ecuador&page=1" },
          py: { label: "Paraguay", url: "/results/?term=Paraguay&page=1" },
          pe: { label: "Peru", url: "/results/?term=Peru&page=1" },
          sr: { label: "Suriname", url: "/results/?term=Suriname&page=1" },
          uy: { label: "Uruguay", url: "/results/?term=Uruguay&page=1" },
          ve: { label: "Venezuela", url: "/results/?term=Venezuela&page=1" },
        },
      },
      north_america: {
        title: "North America",
        url: "/itineraries/north-america/",
        items: {
          aw: { label: "Aruba", url: "/results/?term=Aruba&page=1" },
          bb: { label: "Barbados", url: "/results/?term=Barbados&page=1" },
          bz: { label: "Belize", url: "/results/?term=Belize&page=1" },
          ca: { label: "Canada", url: "/results/?term=Canada&page=1" },
          cr: {
            label: "Costa Rica",
            url: "/results/?term=Costa%20Rica&page=1",
          },
          cu: { label: "Cuba", url: "/results/?term=Cuba&page=1" },
          cw: { label: "Curacao", url: "/results/?term=Curacao&page=1" },
          do: {
            label: "Dominican Republic",
            url: "/results/?term=Dominican%20Republic&page=1",
          },
          sv: {
            label: "El Salvador",
            url: "/results/?term=El%20Salvador&page=1",
          },
          gp: { label: "Guadeloupe", url: "/results/?term=Guadeloupe&page=1" },
          gt: { label: "Guatemala", url: "/results/?term=Guatemala&page=1" },
          hn: { label: "Honduras", url: "/results/?term=Honduras&page=1" },
          jm: { label: "Jamaica", url: "/results/?term=Jamaica&page=1" },
          mx: { label: "Mexico", url: "/results/?term=Mexico&page=1" },
          ni: { label: "Nicaragua", url: "/results/?term=Nicaragua&page=1" },
          pr: {
            label: "Puerto Rico",
            url: "/results/?term=Puerto%20Rico&page=1",
          },
          bs: {
            label: "The Bahamas",
            url: "/results/?term=The%20Bahamas&page=1",
          },
          tt: {
            label: "Trinidad and Tobago",
            url: "/results/?term=Trinidad%20and%20Tobago&page=1",
          },
          tc: {
            label: "Turks and Caicos Islands",
            url: "/results/?term=Turks%20and%20Caicos%20Islands&page=1",
          },
          vi: {
            label: "U.S. Virgin Islands",
            url: "/results/?term=U.S.%20Virgin%20Islands&page=1",
          },
          us: {
            label: "United States",
            url: "/results/?term=United%20States&page=1",
          },
        },
      },
      asia: {
        title: "Asia",
        url: "/itineraries/asia/",
        items: {
          af: {
            label: "Afghanistan",
            url: "/results/?term=Afghanistan&page=1",
          },
          am: { label: "Armenia", url: "/results/?term=Armenia%20Asia&page=1" },
          bt: { label: "Bhutan", url: "/results/?term=Bhutan&page=1" },
          bn: { label: "Brunei", url: "/results/?term=Brunei&page=1" },
          kh: { label: "Cambodia", url: "/results/?term=Cambodia&page=1" },
          cn: { label: "China", url: "/results/?term=China%20Asia&page=1" },
          ge: { label: "Georgia", url: "/results/?term=Georgia%20Asia&page=1" },
          hk: { label: "Hong Kong", url: "/results/?term=Hong%20Kong&page=1" },
          in: { label: "India", url: "/results/?term=India&page=1" },
          id: { label: "Indonesia", url: "/results/?term=Indonesia&page=1" },
          il: { label: "Israel", url: "/results/?term=Israel&page=1" },
          jp: { label: "Japan", url: "/results/?term=Japan&page=1" },
          jo: { label: "Jordan", url: "/results/?term=Jordan&page=1" },
          kz: { label: "Kazakhstan", url: "/results/?term=Kazakhstan&page=1" },
          la: { label: "Laos", url: "/results/?term=Laos&page=1" },
          my: { label: "Malaysia", url: "/results/?term=Malaysia&page=1" },
          mv: {
            label: "Maldives",
            url: "/results/?term=Maldives%20Asia&page=1",
          },
          mn: { label: "Mongolia", url: "/results/?term=Mongolia&page=1" },
          mm: {
            label: "Myanmar (Burma)",
            url: "/results/?term=Myanmar%20(Burma)&page=1",
          },
          om: { label: "Oman", url: "/results/?term=Oman&page=1" },
          pk: { label: "Pakistan", url: "/results/?term=Pakistan&page=1" },
          ph: {
            label: "Philippines",
            url: "/results/?term=Philippines&page=1",
          },
          ru: { label: "Russia", url: "/results/?term=Russia&page=1" },
          sg: { label: "Singapore", url: "/results/?term=Singapore&page=1" },
          kr: {
            label: "South Korea",
            url: "/results/?term=South%20Korea%20Asia&page=1",
          },
          lk: { label: "Sri Lanka", url: "/results/?term=Sri%20Lanka&page=1" },
          tw: { label: "Taiwan", url: "/results/?term=Taiwan&page=1" },
          tj: { label: "Tajikistan", url: "/results/?term=Tajikistan&page=1" },
          th: { label: "Thailand", url: "/results/?term=Thailand&page=1" },
          ae: {
            label: "United Arab Emirates",
            url: "/results/?term=United%20Arab%20Emirates&page=1",
          },
          uz: { label: "Uzbekistan", url: "/results/?term=Uzbekistan&page=1" },
          vn: { label: "Vietnam", url: "/results/?term=Vietnam&page=1" },
        },
      },
      oceania: {
        title: "Oceania",
        url: "/itineraries/oceania/",
        items: {
          au: { label: "Australia", url: "/results/?term=Australia&page=1" },
          pg: {
            label: "Papua New Guinea",
            url: "/results/?term=Papua%20New%20Guinea&page=1",
          },
          nz: {
            label: "New Zealand",
            url: "/results/?term=New%20Zealand&page=1",
          },
        },
      },
      africa: {
        title: "Africa",
        url: "/itineraries/africa/",
        items: {
          bw: { label: "Botswana", url: "/results/?term=Botswana&page=1" },
          bf: {
            label: "Burkina Faso",
            url: "/results/?term=Burkina%20Faso&page=1",
          },
          cv: {
            label: "Cape Verde",
            url: "/results/?term=Cape%20Verde&page=1",
          },
          td: { label: "Chad", url: "/results/?term=Chad&page=1" },
          eg: { label: "Egypt", url: "/results/?term=Egypt&page=1" },
          et: { label: "Ethiopia", url: "/results/?term=Ethiopia&page=1" },
          ke: { label: "Kenya", url: "/results/?term=Kenya&page=1" },
          mu: { label: "Mauritius", url: "/results/?term=Mauritius&page=1" },
          ma: { label: "Morocco", url: "/results/?term=Morocco&page=1" },
          na: { label: "Namibia", url: "/results/?term=Namibia&page=1" },
          sn: { label: "Senegal", url: "/results/?term=Senegal&page=1" },
          za: {
            label: "South Africa",
            url: "/results/?term=South%20Africa&page=1",
          },
          tz: { label: "Tanzania", url: "/results/?term=Tanzania&page=1" },
          tn: { label: "Tunisia", url: "/results/?term=Tunisia&page=1" },
          ug: { label: "Uganda", url: "/results/?term=Uganda&page=1" },
          zm: { label: "Zambia", url: "/results/?term=Zambia&page=1" },
          zw: { label: "Zimbabwe", url: "/results/?term=Zimbabwe&page=1" },
        },
      },
    },
  },
  categories: {
    title: "Categories",
    items: {
      surfing: { label: "Surfing", url: "/results/?term=surfing&page=1" },
      wine: { label: "Wine", url: "/results/?term=wine&page=1" },
      beach: { label: "Beach", url: "/results/?term=beach&page=1" },
      family: { label: "Family", url: "/results/?term=family&page=1" },
      hiking: { label: "Hiking", url: "/results/?term=hiking&page=1" },
      solo: { label: "Solo", url: "/results/?term=solo&page=1" },
      snorkeling: {
        label: "Snorkeling",
        url: "/results/?term=snorkeling&page=1",
      },
      mountains: { label: "Mountains", url: "/results/?term=mountains&page=1" },
      diving: { label: "Diving", url: "/results/?term=diving&page=1" },
      foodie: { label: "Foodie", url: "/results/?term=foodie&page=1" },
      waterfalls: {
        label: "Waterfalls",
        url: "/results/?term=waterfalls&page=1",
      },
      skiing: { label: "Skiing", url: "/results/?term=skiing&page=1" },
    },
  },
  topics: {
    items: {
      favorite_hikes_in_europe: {
        label: "Favorite Hikes in Europe",
        url: "/topics/travelers-favorite-self-guided-hiking-tours-in-europe/99027d9d-5f73-4e8f-a5f8-a1ba57652594/",
      },
      scuba_diving_vacations: {
        label: "Scuba Diving Vacations",
        url: "/topic/6-scuba-diving-vacations-for-2023/23fbbf40-1735-11ed-a040-17638e30f285/",
      },
      road_trips_in_europe: {
        label: "Road Trips in Europe",
        url: "/topic/road-trips-in-europe/26885100-0d86-11ed-845b-15073466d5d2/",
      },
      cheese_and_wine_destinations: {
        label: "5 Cheese and Wine Destinations",
        url: "/topics/5-favorite-destinations-for-cheese-and-wine-tasting/333697a5-ad20-4a79-b3bf-c132980eadb5/",
      },
      street_art_cities: {
        label: "6 Best Cities for Street Art",
        url: "/topics/6-best-cities-for-street-art-around-the-world/6a7411c1-f395-4aba-8db6-058b01ec699a/",
      },
      eat_healthy_while_traveling: {
        label: "Eat Healthy While Traveling",
        url: "/topics/how-to-eat-healthy-while-traveling-abroad/0d5875f0-a6fb-445c-b18f-5bd6d6edef52/",
      },
      beautiful_train_stations: {
        label: "14 Beautiful Train Stations",
        url: "/topics/14-beautiful-and-old-train-stations-to-see-in-the-world/63226d07-5711-466d-872e-0fcd2afd763a/",
      },
      foodie_trips: {
        label: "Foodie Trips",
        url: "/topic/foodie-trips/69c36600-0f5a-11ed-bd21-09128ab1a6a2/",
      },
      peru_travel_itineraries: {
        label: "Peru Travel Itineraries",
        url: "/topic/peru-travel-itineraries/872b85a0-4093-11ed-a1d3-0936eb4b925a/",
      },
      colombia_travel: {
        label: "Best Places to Travel in Colombia",
        url: "/topic/best-places-to-travel-in-colombia---cartagena-medellin---coffee/ead62260-0c5c-11ed-a9ce-d9cda56edc23/",
      },
      southeast_asia_vacations: {
        label: "Southeast Asia Vacations",
        url: "/topic/southeast-asia-vacations/a204c6b0-0c37-11ed-8840-2befd8981496/",
      },
      solo_trips: {
        label: "Solo Trips",
        url: "/topic/solo-trips/dcb93a70-1d71-11ed-aaf7-4d290904da33/",
      },
    },
  },
};
