import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  // General Styles
  "@global": {
    // Animations
    "@keyframes blink": {
      "from, to": {
        opacity: 0,
      },
      "50%": {
        opacity: 1,
      },
    },

    "@keyframes spin": {
      "100%": {
        transform: "rotate(360deg)",
      },
    },

    "@keyframes placeHolderShimmer": {
      from: {
        backgroundPosition: "-100em 0",
      },
      to: {
        backgroundPosition: "100em 0",
      },
    },

    "html, body": {
      ...theme.typography.body1,

      "&, & > div": {
        height: "100%",
      },
    },

    ".gm-ui-hover-effect": {
      display: "none !important",
    },

    ".pac-container": {
      height: "auto",
      paddingBottom: "1em",
      zIndex: "9999",
    },

    body: {
      backgroundColor: theme.palette.primary.main,

      "@media print": {
        backgroundColor: "transparent",
      },

      "& > iframe": {
        zIndex: 9999,
      },

      "&.no-scroll": {
        overflow: "hidden",
        height: "100vh",
        touchAction: "none" /* Prevent touch gestures on mobile */,
      },
    },

    img: {
      maxWidth: "100%",
    },

    "#nprogress": {
      pointerEvents: "none",

      "& .bar": {
        background: theme.palette.secondary.main,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "2px",
        zIndex: 99999,
      },
    },
  },

  // Main Containers
  layout: {
    // * expands height to 100% of screen - it has bugs with overflow
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    // overflow: "clip", // not supported on IOS
    width: "100%",
    zIndex: 1,

    "& .container": {
      display: "flex",
      flex: 1,
      margin: "0 auto",
      maxWidth: theme.breakpoints.values.lg,
      width: "100%",
      // zIndex: 1
    },

    "@media print": {
      "& > div, & > footer": {
        display: "none",
      },
    },
  },

  mainContainer: {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    flexFlow: "column",
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: "flex-start",
    padding: `160px ${theme.gutter}px ${theme.spacing(10.5)}`,
    position: "relative",
    width: "100%",
    zIndex: 10,

    [theme.breakpoints.up("md")]: {
      paddingTop: 116,
    },

    "@media print": {
      backgroundColor: "transparent",
      paddingTop: "0 !important",
    },
  },

  mainContent: {
    padding: "0.8em 0 0",

    [theme.breakpoints.up("md")]: {
      paddingTop: "1.2em",
    },
  },
}));

export default styles;
