import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    boxShadow: theme.atlasGuru.boxShadow(),
    margin: `0 0 ${theme.spacing(2)}`,
    transition: theme.transitions.create("box-shadow"),

    "@media print": {
      boxShadow: "none !important",
    },
  },

  rounded: {
    borderRadius: 20,
  },

  // CardHeader
  header: {},

  userWrapper: {},

  userLink: {
    "&:hover": {
      "& $userName span": {
        color: theme.palette.common.highlight,
      },
    },
  },

  userName: {
    ...theme.typography.caption,
    color: theme.palette.grey[700],
    whiteSpace: "nowrap",

    "& span": {
      color: theme.palette.grey[800],
      transition: theme.transitions.create("color"),
    },
  },

  avatar: {},

  // CardSummary
  summary: {},

  title: {
    margin: "0.2em 0 0.6em",
    transition: theme.transitions.create("color"),
  },

  description: {},

  // CardContent
  content: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
});

export default makeStyles(styles);
