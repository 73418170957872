/*
 * This hook is an utility's wrapper of the Material-UI mediaquery
 * It is intended to extend the functionality of the original hook and provided more
 * features.
 * In case you want to read more in detail, check:
 * https://material-ui.com/components/use-media-query/#usemediaquery
 */
import { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    height: 0,
    left: 0,
    position: "absolute",
    top: 0,
    visibility: "hidden",
    width: 0,
  },
}));

const useViewport = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isTabletUp = useMediaQuery(theme.breakpoints.up("md"));
  const isDesktopUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [isViewportDefined, setIsViewportDefined] = useState(false);

  // return the common predefined styles to when the viewport is not defined
  const loadingStyles = useStyles();

  useEffect(() => {
    setIsViewportDefined(isMobile || isMobileUp || isTabletUp || isDesktopUp);
  }, [isMobile, isTabletUp, isDesktopUp, isMobileUp, setIsViewportDefined]);

  return {
    isMobile,
    isMobileUp,
    isTabletUp,
    isDesktopUp,
    isViewportDefined,
    loadingStyles: isViewportDefined ? "" : loadingStyles.root,
  };
};

export default useViewport;
