import { googleMapsApiKey } from "../../config";
import { GMAP_MIN_ZOOM } from "../constants";

import styles from "./styles";

// create map using gmap options
export function createMap(el, options) {
  const map = new google.maps.Map(el, { ...options, styles });
  return map;
}

// add marker passing gmap options
export function addMarker(options) {
  const marker = new google.maps.Marker(options);
  return marker;
}

// create markers and auto center map
export function addMarkers(map, markersOptions) {
  let markers = [];

  // When there is only one marker its moves the map to
  // the marker's position and set the zoom to the minimum
  // otherwise it sets the map to the bounds of all markers
  if (markersOptions.length > 1) {
    const bounds = new google.maps.LatLngBounds();
    markers = markersOptions.map((options) => {
      if (options.position) {
        bounds.extend(options.position);
      }
      return addMarker({ ...options, map });
    });
    map.fitBounds(bounds);
  } else {
    const marker = addMarker({ ...markersOptions[0], map });
    markers.push(marker);
    map.setCenter(marker.getPosition());
    map.setZoom(GMAP_MIN_ZOOM + 3);
  }

  return markers;
}

// add infowindows to markers
let currInfowindow;

function setInfowindowTemplate(content, options) {
  const rootStyles = "cursor:pointer;padding-left:6px;max-width:140px";
  const containerStyles =
    "display:flex;align-items:center;justify-content:space-around;margin: 0;min-width:80px";
  const arrorStyles = "height:10px;float:right;width:10px";
  const labelStyles =
    "flex-grow:1;padding-right:17px;color:#669D4C;text-decoration:underline";

  return `
    <div style="${rootStyles}">
      <!--<img src="${options.cover}" style="padding-top: 5px;width:100%" />-->
      <p style="${containerStyles}">
        <span style="${labelStyles}">${content}</span>
        <img src="${options.arrow}" style="${arrorStyles}" />
      </p>
    </div>
  `;
}

export function openInfowindow(map, marker, tooltipOptions, callback) {
  currInfowindow = new google.maps.InfoWindow();
  const div = document.createElement("div");

  div.innerHTML = setInfowindowTemplate(marker.title, tooltipOptions);
  div.onclick = () => {
    callback(marker);
  };

  currInfowindow.setContent(div);
  currInfowindow.open(map, marker);

  google.maps.event.addListener(map, "click", () => {
    currInfowindow.close();
  });
}

export function addInfowindows(
  map,
  markers,
  tooltipOptions,
  callback = () => {}
) {
  markers.forEach((marker) => {
    marker.addListener("click", () => {
      if (currInfowindow) currInfowindow.close();
      openInfowindow(map, marker, tooltipOptions, callback);
      map.panTo(marker.getPosition());
    });
  });
}

// create lines between markers
export function addPaths(map, coords) {
  const lineSymbol = {
    path: "M 0,0.5 0,2",
    strokeOpacity: 1,
    scale: 2,
  };
  const flightPath = new google.maps.Polyline({
    path: coords,
    geodesic: true,
    strokeColor: "#333333",
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "10px",
      },
    ],
  });
  flightPath.setMap(map);
}

export function addEvent(el, event, callback = () => {}) {
  el.addListener(event, () => {
    callback(el);
  });
}

export function triggerEvent(el, event) {
  // eslint-disable-next-line
  new google.maps.event.trigger(el, event);
}

// loads gmaps library dynamically and register callback
const gmapsCallbacks = [];
const initFunctionName = "gmapInit";
const gmapsId = "gmaps";
let loaded = false;
export function loadGoogleMaps(callback) {
  if (loaded && typeof callback === "function") {
    callback();
    return;
  }
  if (typeof callback === "function") {
    gmapsCallbacks.push(callback);
  }
  if (!document.getElementById(gmapsId)) {
    const { body } = document;
    const script = document.createElement("script");
    script.setAttribute("id", gmapsId);
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&loading=async&libraries=places&callback=${initFunctionName}`;
    body.appendChild(script);
  }
}

// register global function for maps init
if (process.browser) {
  window[initFunctionName] = () => {
    loaded = true;
    gmapsCallbacks.forEach((cb) => cb());
  };
}
