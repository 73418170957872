const Warning = () => (
  <svg viewBox="0 0 200 200">
    <circle
      cx="100"
      cy="100"
      r="97"
      stroke="currentColor"
      fill="none"
      strokeWidth="6"
    />
    <text x="80" y="140" fontSize="120" fontFamily="Arial" fill="currentColor">
      !
    </text>
  </svg>
);

export default Warning;
