import MuiPopper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { makeStyles } from "@mui/styles";
import Grow from "./Grow";
import Paper from "./Paper";
import { IconClose } from "./icons";
import IconButton from "./IconButton";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1600,
  },

  popperWrapper: {},

  popperContent: {
    width: "100%",
  },

  closeBtn: {
    color: theme.palette.primary.dark,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 20,

    "&:hover svg > g": {
      color: theme.palette.common.white,

      "& circle": {
        fill: theme.palette.common.highlight,
      },
    },

    "& svg": {
      color: theme.palette.secondary.dark,

      "& > g": {
        strokeWidth: 4,
      },

      "& circle": {
        fill: theme.palette.common.white,
      },
    },
  },

  // ----------------
  // variant=default
  // ----------------
  default: {
    "& $popperWrapper": {
      position: "relative",
    },

    "& $popperContent": {
      padding: theme.spacing(1),
    },
  },
}));

const Popover = ({
  open,
  children,
  anchorEl,
  flip = false,
  arrow = null,
  transition = true,
  disablePortal = false,
  showCloseBtn = true,
  className = "",
  classes: rootClasses,
  onClose = () => {},
  variant = "default",
  transitionId = "ui-popper",
  // onPopperEnter = () => {},
  // onPopperExited = () => {},
  ...rest
}) => {
  const classes = useStyles({ classes: rootClasses });
  const [clickawayEnabled, setClickawayEnabled] = useState(true);

  const handleClose = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (open) onClose(evt);
  };

  // enable or disable the clickaway listener to avoid calling this all time
  useEffect(() => {
    setClickawayEnabled(open);
  }, [open]);

  const renderContentWithTransition = ({ TransitionProps }) => (
    <Grow {...TransitionProps} id={`${transitionId}-${variant}`}>
      <Paper className={classes.popperWrapper}>
        {showCloseBtn && (
          <IconButton
            href="#"
            className={classes.closeBtn}
            onClick={handleClose}
            size="large"
          >
            <IconClose size="md" viewBox="-6 -6 212 212" />
          </IconButton>
        )}

        {clickawayEnabled && (
          <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.popperContent}>{children}</div>
          </ClickAwayListener>
        )}

        {!clickawayEnabled && (
          <div className={classes.popperContent}>{children}</div>
        )}
      </Paper>
    </Grow>
  );

  return (
    <MuiPopper
      {...rest}
      open={open}
      anchorEl={anchorEl}
      transition={transition}
      disablePortal={disablePortal}
      className={`${className} ${classes.root} ${classes[variant]}`}
      modifiers={[
        {
          flip: { enabled: flip },
          arrow: { enabled: Boolean(arrow), element: arrow },
          ...rest.modifiers,
        },
      ]}
    >
      {(props) => renderContentWithTransition(props)}
    </MuiPopper>
  );
};

export default Popover;
