import theme from "@/lib/theme";
import { buildUrl } from "@/lib/useCloudinary";
import { CUSTOM_CROP_TRANSFORMATIONS } from "@/lib/constants";

const getMediaType = (media = "") => media.replace("@media ", "");

export const transformations = {
  ...CUSTOM_CROP_TRANSFORMATIONS,
  fetchFormat: "auto",
  startOffset: 1.5,
  quality: 50,
};

export const getSrcSet = ({
  cloudId,
  src,
  dpr,
  transformations,
  postTransformations,
}) =>
  cloudId
    ? buildUrl(cloudId, transformations, { ...postTransformations, dpr })
    : src;

export const breakpoints = {
  default: {
    media: "all",
  },
  all: {
    media: "all",
  },
  xs: {
    media: getMediaType(theme.breakpoints.down("sm")),
  },
  sm: {
    media: getMediaType(theme.breakpoints.up("sm")),
  },
  md: {
    media: getMediaType(theme.breakpoints.up("md")),
  },
  lg: {
    media: getMediaType(theme.breakpoints.up("lg")),
  },
};
