const styles = (theme) => ({
  root: {
    background: theme.palette.primary.main,
    flexShrink: 0,
    position: "relative",
    width: "100%",
    zIndex: 10,

    "& a": {
      color: theme.palette.common.white,
    },
  },

  footerDecorative: {
    userSelect: "none",
    transform: "translateX(-50%)",
    position: "absolute",
    left: "50%",
    bottom: "100%",
  },

  container: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  },

  row: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "30vh",
  },

  logo: {
    maxWidth: 105,
  },

  navigation: {
    textAlign: "center",
    width: "100%",
  },

  socialLinks: {
    margin: `${theme.spacing(5)} 0`,

    "& > a": {
      display: "inline-block",
      margin: theme.spacing(1),
    },

    "& .icon > svg": {
      width: "1.875em",
      height: "1.875em",

      [theme.breakpoints.up("sm")]: {
        width: "2.375em",
        height: "2.375em",
      },
    },
  },

  bottomNav: {
    ...theme.typography.button,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "none",
    margin: "0 auto",
    textAlign: "center",
    width: "100%",

    "& a": {
      display: "inline-block",
      // fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(1.5),
      whiteSpace: "nowrap",

      [theme.breakpoints.down("md")]: {
        // display: "block",
        margin: theme.spacing(1),
      },
    },
  },
});

export default styles;
