import { useState, useRef, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { delay } from "lodash-es";
import { withStyles } from "@mui/styles";
import useFullscreenStatus from "../../lib/useFullscreenStatus";
import { IconFullScreen, IconClose } from "./icons";
import Button from "./Button";

const styles = (theme) => ({
  root: {
    "&$fullscreen": {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      overflow: "hidden",
      textAlign: "center",
    },

    "&, & $content, & $innerContent, & $media": {
      display: "inline-block",
    },

    "& $content": {
      textAlign: "left",
    },
  },

  fullscreen: {
    backgroundColor: theme.palette.primary.main,
  },

  button: {
    minWidth: 0,
    padding: theme.spacing(1),
  },

  default: {},

  content: {},

  innerContent: {
    margin: "0 auto",
    position: "relative",
  },

  media: {},

  actions: {
    display: "flex",
    justifyContent: "flex-end",
    left: "1em",
    position: "absolute",
    right: 0,
    top: 0,
  },

  icon: {
    "& > svg": {
      color: theme.palette.grey[100],
      // height: theme.gutter,
      // width: theme.gutter,
    },

    "& circle": {
      fill: "transparent !important",
    },
  },

  iconClose: {
    "& > svg > g": {
      strokeWidth: 4,
    },
  },
});

function FullScreenView({ children, classes }) {
  const maximizableElement = useRef(null);
  const mediaElement = useRef(null);
  const [marginTop, setMarginTop] = useState(null);
  const [width, setWidth] = useState(null);
  const fullscreenEnabled =
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.webkitFullscreenEnabled;
  let isFullscreen;
  let setIsFullscreen;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const getImageProps = () => {
    const imgElem = mediaElement.current.children[0];
    const imgWidth = imgElem.offsetWidth;
    const needsMargin =
      mediaElement.current.offsetHeight > imgElem.offsetHeight;
    const fixedMargin = Math.floor(
      (mediaElement.current.offsetHeight - imgElem.offsetHeight) / 2,
    );

    return { fixedMargin, needsMargin, imgWidth };
  };

  const cleanMediaElement = () => {
    setMarginTop(null);
    setWidth(null);
  };

  const centerMediaElement = () => {
    const { fixedMargin, needsMargin, imgWidth } = getImageProps();

    if (!isFullscreen) {
      if (needsMargin && marginTop === null) {
        setMarginTop(fixedMargin);
      }

      setWidth(imgWidth);
    } else {
      cleanMediaElement();
    }
  };

  const handleExitFullscreen = () => document.exitFullscreen();

  const handleResize = () => {
    isFullscreen = false;
    setIsFullscreen = undefined;

    delay(() => {
      cleanMediaElement();
      centerMediaElement();
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useLayoutEffect(() => {
    centerMediaElement();
  });

  return (
    <section
      ref={maximizableElement}
      className={`${classes.root} ${
        isFullscreen ? classes.fullscreen : classes.default
      }`}
    >
      <div className={classes.content}>
        <div className={classes.innerContent} style={{ marginTop, width }}>
          <div className={classes.media} ref={mediaElement}>
            {children}
          </div>

          {fullscreenEnabled && (
            <aside className={classes.actions}>
              {!isFullscreen && (
                <Button onClick={setIsFullscreen} className={classes.button}>
                  <IconFullScreen className={classes.icon} />
                </Button>
              )}

              {isFullscreen && (
                <Button
                  onClick={handleExitFullscreen}
                  className={classes.button}
                >
                  <IconClose
                    className={`${classes.icon} ${classes.iconClose}`}
                    size="md"
                    viewBox="-6 -6 212 212"
                  />
                </Button>
              )}
            </aside>
          )}
        </div>
      </div>
    </section>
  );
}

FullScreenView.defaultProps = {
  classes: {},
  children: null,
};

FullScreenView.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(FullScreenView);
