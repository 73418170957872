const styles = (theme) => ({
  // Modal
  modal: {},

  modalContainer: {
    overflow: "hidden",
  },

  modalRow: {
    height: "100%",
    padding: "0 !important",
  },

  // modalInner: {
  //   position: "relative"
  // },

  modalPaper: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.common.white,
  },

  modalCloseBtn: {
    padding: 0,
    position: "absolute",
    right: theme.gutter,
    top: theme.gutter / 2,
    zIndex: 20,

    "& svg": {
      color: theme.palette.grey[500],

      "& > g": {
        strokeWidth: 4,
      },
    },
  },

  // Gallery
  gallerySection: {
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      // margin: "0 auto",
      // width: "95%"
    },
  },

  galleryRow: {
    height: "100%",
  },

  // Slider
  slider: {
    position: "relative",
    zIndex: 10,

    "&, & .slick-slider, & .slick-list, & .slick-track, & .slick-slide > div": {
      height: "100%",
    },
  },

  sliderArrows: {
    color: theme.palette.common.white,
    backgroundColor: "transparent",
    top: "50vh",

    [theme.breakpoints.up("sm")]: {
      height: 80,
    },

    "&:hover": {
      backgroundColor: "transparent !important",
      // color: theme.palette.common.highlight
    },

    "& svg": {
      height: 24,
      width: 24,

      [theme.breakpoints.up("sm")]: {
        height: 54,
        width: 54,
      },
    },
  },

  sliderContainer: {
    height: "100%",
  },

  slide: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    margin: 0,
  },

  slideTitle: {
    color: theme.palette.grey[500],
    margin: `${theme.spacing(2)} ${theme.gutter}px ${theme.gutter}px`,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.gutter * 2,
      marginRight: theme.gutter * 2,
    },
  },

  slideImage: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
  },

  slideImageContent: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    // alignItems: "center",
    position: "relative",
    width: `calc(100% - ${theme.gutter * 2}px)`,
  },

  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },

  imageContainer: {
    background: "no-repeat 50% 50%",
    backgroundSize: "contain",
    height: "80%",
    width: "80%",
    position: "relative",
    zIndex: 10,

    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0,
      width: "100%",
      height: "100%",
    },
  },

  videoContainer: {
    height: "80%",
    width: "80%",
    position: "relative",
    zIndex: 10,
  },

  videoBox: {
    position: "static",
  },

  videoElem: {
    display: "inline",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  // fullScreenWrapper: {
  //   "&, & $fullScreenContent, & $fullScreenInnerContent, & $fullScreenMedia": {
  //     display: "block",
  //     height: "100%",
  //     textAlign: "center",
  //   },

  //   "& $fullScreenContent": {
  //     maxHeight: "100%",
  //     maxWidth: "100%",
  //   },
  // },

  // fullscreen: {
  //   "& $fullScreenMedia": {
  //     alignItems: "center",
  //     display: "flex",
  //     justifyContent: "center",
  //   },
  // },

  // fullScreenContent: {
  //   bottom: 0,
  //   left: 0,
  //   margin: "auto",
  //   position: "absolute",
  //   right: 0,
  //   top: 0,
  // },

  // fullScreenInnerContent: {
  //   height: "auto",
  //   position: "relative",
  // },

  // fullScreenMedia: {},

  // imgPlaceholder: {
  //   opacity: 0,
  //   position: "absolute",
  //   top: 0,
  // },

  loading: {
    position: "absolute",
    top: "50%",
    marginTop: -25,
  },

  slideFooter: {
    marginTop: theme.gutter,
    maxWidth: 545,
    overflow: "hidden",
    padding: `0 ${theme.gutter}px`,
  },

  slideCaption: {
    color: theme.palette.common.white,
    marginBottom: theme.gutter,
    textAlign: "center",
  },

  socialNav: {
    marginBottom: theme.gutter,

    "& > a": {
      display: "inline-block",

      "&:first-child": {
        borderRight: `1px dashed rgba(255,255,255,0.2)`,
        marginRight: 20,
        paddingRight: 10,
      },
    },

    "& span": {
      display: "flex",
    },

    "& .icon > svg": {
      height: 32,
      marginRight: 10,
      width: 32,
    },
  },
});

export default styles;
