import { makeStyles } from "@mui/styles";

const topicTitleContainerWidth = 160;

const styles = (theme) => ({
  root: {
    position: "relative",
    // zIndex: 0,
  },

  isPopperActive: {
    // zIndex: 1600,
  },

  moreTopicsPopper: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },

  container: {},

  emptyContainer: {},

  navigationWrapper: {},

  navigationInner: {},

  navigationEllipsis: {},

  title: {},

  navigation: {},

  link: {
    marginRight: "1em",

    "&:last-of-type": {
      marginRight: 0,
    },

    "&:hover $text": {
      color: theme.palette.common.highlight,
    },
  },

  text: {
    ...theme.typography.h5,
    lineHeight: 2,
  },

  buttonWrapper: {},

  button: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    minWidth: "7em",
    paddingLeft: 0,
    paddingRight: 0,

    "&:hover": {
      color: theme.palette.common.highlight,
    },
  },

  popperWrapper: {
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  },

  popperTitle: {
    paddingBottom: "1em",
  },

  popperList: {
    columnCount: 2,
    maxWidth: "50vw",
  },

  popperLink: {
    ...theme.typography.h5,
    color: theme.palette.secondary.main,
    lineHeight: 2,
    display: "block",
    marginBottom: "0.8em",
    width: "100%",

    "&:hover": {
      color: theme.palette.common.highlight,
    },
  },

  // ----------------
  // variant=default
  // ----------------
  default: {},

  // ----------------
  // variant=inline
  // ----------------
  inline: {
    "& $navigationInner": {
      textAlign: "center",
      minHeight: "5.8em",

      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        minHeight: "2.4em",
        display: "flex",
        gap: "1em",
      },
    },

    "& $title": {
      color: theme.palette.grey[150],
      paddingRight: "1em",
      lineHeight: 1.4,
      marginBottom: "0.4em",

      [theme.breakpoints.up("md")]: {
        display: "inline",
        marginBottom: 0,
      },
    },

    "& $navigationEllipsis": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

      "& $navigation": {
        display: "inline",
      },
    },
  },

  // ----------------
  // variant=stripe
  // ----------------
  stripe: {
    margin: "0 auto 1.4em",
    maxWidth: theme.breakpoints.values.lg,

    "& $navigationWrapper": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: topicTitleContainerWidth,
      },
    },

    "& $navigationInner": {
      display: "flex",
      alignItems: "center",
      flexFlow: "column",
      minHeight: "5.8em",

      [theme.breakpoints.up("md")]: {
        flexFlow: "row",
        gap: theme.spacing(),
        minHeight: "2.2em",
      },
    },

    "& $title": {
      color: theme.palette.grey[150],
      lineHeight: 1.4,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: "0.4em",
      textAlign: "center",

      [theme.breakpoints.up("md")]: {
        marginTop: -1,
        marginBottom: 0,
        transform: "translateY(-50%)",
        position: "absolute",
        right: "100%",
        top: "50%",
        width: topicTitleContainerWidth,
        textAlign: "left",
      },
    },

    "& $navigationEllipsis": {
      minWidth: 0,
      position: "relative",

      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },

      "& $navigation": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
});

export default makeStyles(styles);
