import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

const styles = () => ({
  root: {},

  absolute: {
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: "99999",
  },

  fixed: {
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: "99999",
  },

  relative: {},
});

const Loading = ({
  classes,
  color,
  className,
  position,
  size,
  variant,
  ...props
}) => {
  const loadingClasses = `${className} ${
    classes[position] ?? classes.relative
  }`;

  return (
    <>
      {variant === "circular" && (
        <CircularProgress
          className={loadingClasses}
          color={color}
          size={size}
          {...props}
        />
      )}
      {(variant === "linear" || variant === "determinate") && (
        <LinearProgress className={loadingClasses} color={color} {...props} />
      )}
    </>
  );
};

Loading.defaultProps = {
  classes: {},
  className: "",
  color: "inherit",
  position: "relative",
  size: 15,
  variant: "circular",
};

Loading.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
};

export default withStyles(styles)(Loading);
