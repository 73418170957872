import { gql } from "@apollo/client";

export const ITINIERARY_AI_FRAGMENT = gql`
  fragment ItineraryAiData on ItineraryAi {
    itineraryId
    tripTitle
    url
    slug
    userId
    published
    numOfDays
    regionName
    shortDescription
    weatherInfo {
      summary
    }
    aiMetadata {
      specialRequests
      locations
      aiStatus
      prompt
      error
    }
    countries
    userProfile {
      userId
      cloudId
      screenName
      profilePicUrl
    }
    tripPhotos {
      locationName
      photoUrl
      cloudId
      url
    }
    locationsVisited {
      locationId
      numOfDays
      experienceDescription
      tripPhotos {
        photoUrl
        cloudId
        url
        isHero
      }
      location {
        name
        formatted_address
        originalName
        geometry {
          location {
            lat
            lng
          }
        }
      }
      placesVisited {
        placeId
        originalName
        experienceDescription
      }
      recommendedRestaurants {
        description
        image
        name
        rating
        url
      }
      # recommendedHotels {
      #   description
      #   image
      #   name
      #   rating
      #   url
      # }
    }
    relatedItineraries {
      url
      itineraryId
      tripTitle
      numOfDays
      photoUrl
      cloudId
      regionName
      countries {
        name
      }
      userProfile {
        screenName
        profilePicUrl
        cloudId
        userId
      }
    }
    quotes {
      screenName
      profilePicUrl
      cloudId
      userId
      quote
    }
  }
`;

export const AI_ITINERARY_QUERY = gql`
  query AI_ITINERARY_QUERY($itineraryId: ID!) {
    itineraryAi(itineraryId: $itineraryId) {
      ...ItineraryAiData
    }
  }
  ${ITINIERARY_AI_FRAGMENT}
`;

export const AI_ITINERARY_QUERY_NO_CACHE = gql`
  query AI_ITINERARY_QUERY_NO_CACHE($itineraryId: ID!) {
    itineraryAi(itineraryId: $itineraryId) {
      ...ItineraryAiData
    }
  }
  ${ITINIERARY_AI_FRAGMENT}
`;

export const AI_ITINERARIES_QUERY_NO_CACHE = gql`
  query AI_ITINERARIES_QUERY_NO_CACHE($userId: ID!) {
    itinerariesAiUser(userId: $userId) {
      url
      slug
      tripTitle
      numOfDays
      dateCreated
      itineraryId
      aiMetadata {
        specialRequests
      }
      locationsVisited {
        location {
          geometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const UPSERT_ITINERARY_AI = gql`
  mutation UpsertItineraryAi($input: ItineraryAiInput) {
    upsertItineraryAi(input: $input) {
      itineraryId
    }
  }
`;

export const AI_ITINERARY_DELETE = gql`
  mutation ($itineraryId: ID!, $userId: ID) {
    deleteItineraryAi(itineraryId: $itineraryId, userId: $userId)
  }
`;
