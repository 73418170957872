import { Fragment } from "react";

import { Image } from "@/ui";
import Source from "./Source";

import { transformations as baseTransformations } from "./utils";

import useStyles from "./styles";

// Reference: https://atlasguru.atlassian.net/wiki/spaces/AWP/pages/1465745409/Responsive+Images

const Picture = ({
  alt = "",
  src = "",
  sizes = [],
  loading = "lazy",
  fetchpriority = "low",
  transformations: pictureTransformations = {},
  classes: rootClasses,
  className = "",
  decoding = "async",
  defaultSizeIndex = 0,
}) => {
  const classes = useStyles({ classes: rootClasses });
  const sizesKeys = Object.keys(sizes);
  const firstSizeKey = sizesKeys[defaultSizeIndex] || "";
  // const lastSizeKey = sizesKeys[sizesKeys.length - 1] || "";

  const {
    height = 0,
    width = 0,
    cloudId = "",
    src: imageSrc,
    transformations: sourceTransformations = {},
  } = sizes[firstSizeKey] || {}; // lastSizeKey

  const transformations = {
    ...baseTransformations,
    ...pictureTransformations,
    ...sourceTransformations,
  };

  const postTransformations = {
    crop: "fit",
    height,
    width,
  };

  return (
    <picture className={`${classes.root} ${className}`}>
      {sizesKeys.length > 0 && (
        <>
          {sizesKeys.map((breakpoint) => (
            <Fragment key={breakpoint}>
              {/* try first load the optimized webp format */}
              <Source
                type="image/webp"
                breakpoint={breakpoint}
                source={sizes[breakpoint]}
                transformations={pictureTransformations}
                width={sizes[breakpoint]?.width || "auto"}
                height={sizes[breakpoint]?.height || "auto"}
              />

              {/* fallback to cloudinary auto format */}
              <Source
                breakpoint={breakpoint}
                source={sizes[breakpoint]}
                transformations={pictureTransformations}
                width={sizes[breakpoint]?.width || "auto"}
                height={sizes[breakpoint]?.height || "auto"}
              />
            </Fragment>
          ))}
        </>
      )}

      {(cloudId || src || imageSrc) && (
        <Image
          alt={alt}
          title={alt}
          width={width}
          height={height}
          cloudId={cloudId}
          loading={loading}
          src={src || imageSrc}
          fetchpriority={fetchpriority}
          transformations={transformations}
          postTransformations={postTransformations}
          className={classes.image}
          decoding={decoding}
        />
      )}
    </picture>
  );
};

export default Picture;
