import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  // slider box
  root: {
    position: "relative",

    "& .slick-list": {
      overflow: "hidden",
      outline: "none",
    },

    "& .slick-track": {
      display: "flex",
    },

    "& .slick-slide": {
      position: "relative",
      zIndex: 0,
    },
  },

  // variant="normal"
  normal: {
    "& $arrow": {
      marginTop: theme.spacing(-3),

      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(-6),
      },
    },
  },

  // variant="minimal"
  minimal: {
    "& $arrow": {
      backgroundColor: "transparent",
      marginTop: theme.spacing(1) * -3.5,
      width: "1em",
    },

    "& $left": {
      left: theme.spacing(0.5),

      [theme.breakpoints.up("sm")]: {
        left: theme.spacing(1) * -4.8,
      },
    },

    "& $right": {
      right: theme.spacing(0.5),

      [theme.breakpoints.up("sm")]: {
        right: theme.spacing(1) * -4.8,
      },
    },

    "& svg": {
      color: theme.palette.secondary.main,
      height: "1em",
      width: "1em",

      [theme.breakpoints.up("sm")]: {
        height: "1.5em",
        width: "1.5em",
      },
    },
  },

  withPagination: {},

  slider: {
    zIndex: 0,
  },

  // next/prev buttons
  arrow: {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    transition: theme.transitions.create("opacity", { duration: 0.2 }),
    zIndex: 10,

    "&$hidden": {
      opacity: 0,
    },
  },

  visible: {},
  hidden: {},

  left: {
    left: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      left: theme.spacing(1) * -1,
    },

    "& svg": {
      bottom: "0.1em",
    },
  },

  right: {
    right: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      right: theme.spacing(1) * -1,
    },

    "& svg": {
      bottom: "0.1em",
    },
  },

  // pagination
  pagination: {
    textAlign: "right",
    padding: `0 ${theme.spacing(1)}`,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(1.6)}`,
    },
  },

  dotBtn: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "50%",
    boxShadow: theme.atlasGuru.boxShadow(),
    cursor: "pointer",
    display: "inline-block",
    height: "0.5em",
    margin: theme.spacing(0.4),
    width: "0.5em",
  },

  active: {
    backgroundColor: theme.palette.common.highlight,
  },
});

export default makeStyles(styles);
